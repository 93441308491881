import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/header/Header";
import { useCart } from "../../context/cart";
import Footer from "../../components/footer/Footer";
import { useAuth } from "../../context/auth";
import "../../css/CartPage.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";

const CartPage = () => {
  const { cart, clearCart, getCartTotal, decrementItem, getCartTT } = useCart();
  const [auth] = useAuth();
  const isLoggedIn = !!auth.token;
  const [productList, setProductList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  //Black Friday or Sale
  const originalTax = (getCartTT() * 0.06).toFixed(2);
  // const toatlTaxes = (getCartTT() * 0.85 * 0.06).toFixed(2);
  // const grandTotal = () => {
  //   if (getCartTT() * 0.85 <= 25) {
  //     return (
  //       parseFloat((6 + parseFloat(getCartTT() * 0.85)).toFixed(2)) +
  //       parseFloat(toatlTaxes)
  //     ).toFixed(2);
  //   } else {
  //     return (
  //       parseFloat((getCartTT() * 0.85).toFixed(2)) + parseFloat(toatlTaxes)
  //     ).toFixed(2);
  //   }
  // };

  const originalTotal = () => {
    if (getCartTT() >0) {
      return (
        parseFloat((7 + parseFloat(getCartTT())).toFixed(2)) +
        parseFloat(originalTax)
      ).toFixed(2);
    } else {
      return (parseFloat(getCartTT()) + parseFloat(originalTax)).toFixed(2);
    }
  };
  //

  useEffect(() => {
    getProductList();
  }, [cart]);

  const apiUrl = `${process.env.REACT_APP_API}`;
  const getProductList = async () => {
    try {
      const promises = cart.map(async (item) => {
        const res = await axios.get(`${apiUrl}/product/${item.product}`);
        const product = res.data;
        product.quantity = item.quantity;
        product.selectedSize = item.selectedSize;
        return product;
      });
      const products = await Promise.all(promises);
      setProductList(products);
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleDecrementItem = (item, size) => {
    decrementItem(item, size);
    toast.success("Removed From Cart");
  };

  return (
    <>
      <Header></Header>
      <div className="container mt-5">
        {cart.length > 0 ? (
          <>
            <div className="d-flex flex-column ">
              <div className="d-flex justify-content-center">
                <h1 style={{ marginTop: "15px", fontWeight: "450" }}>
                  My Cart
                </h1>
              </div>
              <hr />
              {isLoggedIn ? (
                <div className="d-flex justify-content-end">
                  <button className="btn clear-cart" onClick={clearCart}>
                    Clear Cart
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="d-flex flex-column">
              {isLoggedIn ? (
                <>
                  <div className="d-flex flex-column justify-content-center ">
                    {productList.map((item, index) => (
                      <div
                        key={index}
                        className="d-flex flex-row cart-item justify-content-center"
                      >
                        <div className="cart-image-container">
                          <Link to={`/products/${item._id}`}>
                            <img
                              src={item.images.front}
                              alt={item.productName}
                              className="cart-image img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="d-flex flex-column product-desc-container">
                          <h5 style={{ color: "#002e6a" }}>
                            {item.productName}
                          </h5>
                          <span>Size: {item.selectedSize}</span>
                          <span className="quant">Qty: {item.quantity}</span>
                          <span>
                            Price: $
                            {item.salePrice > 0
                              ? item.salePrice
                              : item.productPrice}
                          </span>

                          <hr></hr>

                          <span>
                            Sub total: $
                            {item.quantity *
                              (item.salePrice > 0
                                ? item.salePrice
                                : item.productPrice)}
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <button
                            className="btn"
                            onClick={() =>
                              handleDecrementItem(item, item.selectedSize)
                            }
                          >
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </div>
                        <hr />
                      </div>
                    ))}
                  </div>
                  <hr />
                  <div className="d-flex flex-column mb-5 p-3">
                    
                    
                    {/* Discount 25% */}
                    {/* <h6
                      className="d-flex justify-content-center"
                      style={{ color: "#002e6a", fontWeight: "450" }}
                    >
                      Enjoy an extra<b> &nbsp; 15% &nbsp; </b> discount for End
                      of Year Sale !
                    </h6>*/}
                    <hr></hr>
                    {/* <div className="d-flex justify-content-center">
                      <h5
                        style={{
                          marginBottom: "15px",
                          fontWeight: "400",
                          color: "#002e6a",
                        }}
                      >
                        Taxes:{" "}
                        <h8 style={{ color: "#C70044", fontWeight: "450" }}>
                          {/* ${(getCartTT() * 0.85 * 0.06).toFixed(2)} */}
                    {/* {toatlTaxes} */}
                    {/* </h8> */}
                    {/* </h5> */}
                    {/* </div> */}
                    <div>
                      <div className="d-flex bb ">
                        <h5
                          style={{
                            marginBottom: "15px",
                            fontWeight: "400",
                            color: "#002e6a",
                          }}
                        >
                          Original Total:{" "}
                          <h8 style={{ color: "#C70044", fontWeight: "450" }}>
                            ${getCartTT()}
                          </h8>
                        </h5>
                      </div>
                      {/* <div className="d-flex bb ">
                        <i>
                          {" "}
                          <h5
                            style={{
                              marginBottom: "15px",
                              fontWeight: "400",
                              color: "#002e6a",
                            }}
                          >
                            Saving:{" "}
                            <h8 style={{ color: "#C70044", fontWeight: "450" }}>
                              -${(getCartTT() * 0.15).toFixed(2)}
                            </h8>
                          </h5>
                        </i>
                      </div> */}

                      {/* Black Friday Discount to remove */}
                      {/* <div className="d-flex bb  ">
                        <h5
                          style={{
                            marginBottom: "15px",
                            fontWeight: "400",
                            color: "#002e6a",
                          }}
                        >
                          After Discount Total:{" "}
                          <h8 style={{ color: "#C70044", fontWeight: "450" }}>
                            ${(getCartTT() * 0.85).toFixed(2)}
                          </h8>
                        </h5>
                      </div> */}
                      {/* Black friday discount to remove */}
                      <div className="d-flex bb  ">
                        <h5
                          style={{
                            marginBottom: "15px",
                            fontWeight: "400",
                            color: "#002e6a",
                          }}
                        >
                          Taxes:{" "}
                          <h8 style={{ color: "#C70044", fontWeight: "450" }}>
                            ${(getCartTT() * 0.06).toFixed(2)}
                          </h8>
                        </h5>
                      </div>
                      <div className="d-flex bb  ">
                      {getCartTT() > 0 ? (
                        <h5
                          style={{
                            marginBottom: "15px",
                            fontWeight: "400",
                            color: "#002e6a",
                          }}
                        >
                          Shipping:{" "}
                          <span style={{ color: "#C70044", fontWeight: "450" }}>
                            $7.00
                          </span>
                        </h5>
                      ) : null}
                      </div>
                      
                      {/* Black friday discount */}
                      <div className="d-flex bb  ">
                        {(getCartTT() * 1).toFixed(2) <= 0 ? (
                          <h5
                            style={{
                              marginBottom: "15px",
                              fontWeight: "400",
                              color: "#002e6a",
                            }}
                          >
                            Shipping:{" "}
                            <span
                              style={{ color: "#C70044", fontWeight: "450" }}
                            >
                              $7.00
                            </span>
                          </h5>
                        ) : null}
                      </div>
                      <hr style={{ marginTop: "-5px" }}></hr>
                      <div className=" bb d-flex ">
                        <h2
                          style={{
                            marginBottom: "15px",
                            fontWeight: "400",
                            color: "#002e6a",
                          }}
                        >
                          Grand total:&nbsp;
                          <h8 style={{ color: "#C70044", fontWeight: "450" }}>
                            $
                            {getCartTT() > 0
                              ? (7 + parseFloat(getCartTotal())).toFixed(2)
                              : getCartTotal()}
                          </h8>
                        </h2>
                      </div>
                      <div style={{ marginTop: "-15px", marginBottom: "10px" }}>
                        <i className="grandtotal-info d-flex bb ">
                          {" "}
                          &nbsp;(includes Taxes & Shipping)
                        </i>
                      </div>
                    </div>

                    <div
                      className="d-flex flex-row  justify-content-center"
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                    >
                      <Link to={"/cart/checkout"}>
                        <button
                          type="button"
                          className=" bs btn button add-to-cart"
                        >
                          Checkout
                        </button>
                      </Link>
                    </div>
                    <Link to={"/"}>
                      <h8 className="d-flex justify-content-center mt-3">
                        {" "}
                        Continue Shopping
                      </h8>
                    </Link>
                  </div>
                </>
              ) : (
                <div className="continer">
                  <div className="d-flex justify-content-center">
                    <p>Please log in to view the contents of your cart</p>
                    <br />
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn add-to-cart"
                      onClick={() => navigate("/login")}
                    >
                      Click to login
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "70vh" }}
          >
            <div className="col">
              <div className="d-flex justify-content-center">
                <h1 className="p-5">Empty Cart</h1>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="btn add-to-cart"
                  onClick={() =>
                    navigate(location.state || "/", { replace: true })
                  }
                >
                  Continue Shopping
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer></Footer>
    </>
  );
};

export default CartPage;
