import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/header/AdminHeader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import "../../css/ProductAddPage.css";

const SubCategoryViewPage = () => {
  const [getSubCategoryData, setSubCategoryData] = useState([]);
 

  const apiUrl = `${process.env.REACT_APP_API}`;
  const getData = async (e) => {
    try {
      const res = await fetch(`${apiUrl}/subcategories`, {
        method: "GET",
      });
      const data = await res.json();
      setSubCategoryData(data);
      
    } catch (err) {
      console.log("error");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteSubCategory = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this subcategory?"
    );

    if (confirmDelete) {
      const res2 = await fetch(`${apiUrl}/subcategory/${id}`, {
        method: "DELETE",
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
          mode: "no-cors",
        },
      });

      const deleteSubCategory = await res2.json();
      

      if (res2.status === 422 || !deleteSubCategory) {
        console.log("error");
        toast.error("Error deleting subcategory.");
      } else {
       
        toast.success("Subcategory is deleted!");
        await getData();
      }
    }
  };

  return (
    <>
      <AdminHeader></AdminHeader>
      <div className="mt-5">
        <div className="container">
          <table className="table">
            <thead>
              <tr className="table-dark">
                <th scope="col">Image</th>
                <th scope="col">Subcategory Name</th>
                <th scope="col">Update/Delete</th>
              </tr>
            </thead>
            <tbody>
              {getSubCategoryData.map((element, index) => {
                return (
                  <tr>
                    <td>
                      <img
                        src={element.image}
                        className="img-thumbnail"
                        alt={element.name}
                        width="100"
                        height="100"
                      />
                    </td>
                    <td>{element.name}</td>
                    <td key={index}>
                      <NavLink to={`/admin/subcategory/update/${element.slug}`}>
                        <button className="btn btn-primary">
                          <EditIcon />
                        </button>
                      </NavLink>
                      <button
                        className="btn btn-danger"
                        onClick={() => deleteSubCategory(element._id)}
                      >
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SubCategoryViewPage;
