import React, { useEffect, useState } from "react";
import { useCart } from "../../context/cart";
import { useAuth } from "../../context/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../images/logo.png";
import Search from "../SearchQuery";
import {
  HeartOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Badge } from "antd";
import "./Header.css";
import { toast } from "react-hot-toast";
const Header = () => {
  const [categories, setCategories] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const { getTotalQuantity } = useCart();
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const logout = () => {
    setAuth({ ...auth, user: null, token: "" });
    localStorage.clear();
    navigate("/");
    window.location.reload();
    toast.success("Successful Logout");
  };
  const apiUrl = `${process.env.REACT_APP_API}`;
  useEffect(() => {
    axios
      .get(`${apiUrl}/categories`)
      .then((response) => setCategories(response.data))
      .catch((error) => console.log(error));
  }, [auth.currentUser.id, selectedCategory]);

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
    setIsMenuOpen(false);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };
  const handleMenuOpen = () => {
    setIsMenuOpen(true);
    setIsDropdownOpen(false);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container-fluid">
          <Link to={"/"}>
            <img
              className="logo img-fluid"
              src={logo}
              alt="Logo"
              width="250"
              height="95"
            />
          </Link>
          {/* {isMobile ? (
             
            ) : null} */}
          {isMobile ? (
            <>
              {" "}
              <div className="dropdown">
                <button
                  type="button"
                  className="btn"
                  style={{ marginRight: "-10px" }}
                >
                  <Link to="/cart" className="text-decoration-none text-reset">
                    <div>
                      <Badge count={getTotalQuantity()}>
                        <ShoppingCartOutlined style={{ fontSize: "130%" }} />
                      </Badge>
                      <br />
                      <label style={{ fontWeight: "400" }}>Cart</label>
                    </div>
                  </Link>
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={handleDropdownOpen}
                >
                  <UserOutlined style={{ fontSize: "130%" }} />
                  <br />
                  {auth.token ? (
                    <label style={{ fontWeight: "400" }}>
                      {auth.currentUser.name.first}
                    </label>
                  ) : (
                    <label style={{ fontWeight: "400" }}>Profile</label>
                  )}
                </button>
                <ul
                  className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                  aria-labelledby="dropdownMenuButton2"
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "55%",
                  }}
                >
                  {auth.token ? (
                    <>
                      <li key="profile">
                        <Link
                          to="/profile"
                          className="dropdown-item"
                          style={{ fontWeight: "400" }}
                        >
                          Profile
                        </Link>
                      </li>

                      <li key="wishlist">
                        <hr className="dropdown-divider" />
                      </li>
                      <li key="orders">
                        <Link
                          to="/wishlist"
                          className="dropdown-item"
                          style={{ fontWeight: "400" }}
                        >
                          Wishlist
                        </Link>
                      </li>

                      <li key="div1">
                        <hr className="dropdown-divider" />
                      </li>
                      <li key="orders">
                        <Link
                          to="/orders"
                          className="dropdown-item"
                          style={{ fontWeight: "400" }}
                        >
                          Orders
                        </Link>
                      </li>
                      <li key="div2">
                        <hr className="dropdown-divider" />
                      </li>
                    </>
                  ) : null}
                  {!auth.token ? (
                    <li style={{ fontWeight: "400" }} key="Login">
                      <Link
                        to="/login"
                        className="dropdown-item"
                        style={{ fontWeight: "400" }}
                      >
                        Login/SignUp
                      </Link>
                    </li>
                  ) : (
                    <li key="logout">
                      <Link
                        to="/"
                        className="dropdown-item"
                        onClick={logout}
                        style={{ fontWeight: "400" }}
                      >
                        Logout
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </>
          ) : null}

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleMenuOpen}
            style={{ fontSize: "15px" }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
            id="navbarNav"
          >
            <ul className="navbar-nav mobile">
              {categories.map((category) => (
                <li
                  className={`nav-item ${
                    location.pathname.includes(`/category/${category.name}`) ||
                    location.pathname.includes(`/${category.name}/products`)
                      ? "active"
                      : ""
                  }`}
                  key={category.name}
                >
                  <Link
                    to={`/category/${category.name}`}
                    className="text-decoration-none text-reset"
                    onClick={() => {
                      handleMenuClose();
                    }}
                  >
                    <span className="nav-link">{category.name}</span>
                  </Link>

                  {isMobile ? <hr className="hri" /> : null}
                </li>
              ))}
              <li className="nav-item" key="about">
                <Link
                  to={"/about"}
                  className="text-decoration-none text-reset"
                  onClick={() => {
                    handleMenuClose();
                  }}
                >
                  <span className="nav-link">About-Us</span>
                </Link>

                {isMobile ? <hr className="hri" /> : null}
              </li>
            </ul>

            <div
              className={`navbar-collapse justify-content-center ms-auto mobileSearch  ${
                isMenuOpen ? "show" : ""
              } `}
            >
              <Search />
            </div>
            {!isMobile ? (
              <>
                <div className="dropdown" onMouseLeave={handleDropdownClose}>
                  <button
                    type="button"
                    className="btn dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onMouseEnter={handleDropdownOpen}
                  >
                    <UserOutlined style={{ fontSize: "130%" }} />
                    <br />
                    {auth.token ? (
                      <label style={{ fontWeight: "400" }}>
                        {auth.currentUser.name.first}
                      </label>
                    ) : (
                      <label style={{ fontWeight: "400" }}>Profile</label>
                    )}
                  </button>
                  <ul
                    className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                    aria-labelledby="dropdownMenuButton2"
                  >
                    {auth.token ? (
                      <>
                        <li key="profile">
                          <Link
                            to="/profile"
                            className="dropdown-item"
                            style={{ fontWeight: "400" }}
                          >
                            Profile
                          </Link>
                        </li>

                        <li key="div1">
                          <hr className="dropdown-divider" />
                        </li>
                        <li key="orders">
                          <Link
                            to="/orders"
                            className="dropdown-item"
                            style={{ fontWeight: "400" }}
                          >
                            Orders
                          </Link>
                        </li>
                        <li key="div2">
                          <hr className="dropdown-divider" />
                        </li>
                      </>
                    ) : null}
                    {!auth.token ? (
                      <li style={{ fontWeight: "400" }} key="Login">
                        <Link
                          to="/login"
                          className="dropdown-item"
                          style={{ fontWeight: "400" }}
                        >
                          Login/SignUp
                        </Link>
                      </li>
                    ) : (
                      <li key="logout">
                        <Link
                          to="/"
                          className="dropdown-item"
                          onClick={logout}
                          style={{ fontWeight: "400" }}
                        >
                          Logout
                        </Link>
                      </li>
                    )}
                  </ul>
                  <button type="button" className="btn">
                    <Link
                      to="/wishlist"
                      className="text-decoration-none text-reset"
                    >
                      <div>
                        <HeartOutlined style={{ fontSize: "130%" }} />
                        <br />
                        <label style={{ fontWeight: "400" }}>Wishlist</label>
                      </div>
                    </Link>
                  </button>
                  <button type="button" className="btn">
                    <Link
                      to="/cart"
                      className="text-decoration-none text-reset"
                    >
                      <div>
                        <Badge count={getTotalQuantity()}>
                          <ShoppingCartOutlined style={{ fontSize: "140%" }} />
                        </Badge>
                        <br />
                        <label style={{ fontWeight: "400" }}>Cart</label>
                      </div>
                    </Link>
                  </button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
