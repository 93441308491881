import React, { useState, useEffect } from "react";
import "../../css/CarouselImages.css";
import { toast } from "react-hot-toast";

const AWS = require("aws-sdk");
require("react-responsive-carousel/lib/styles/carousel.min.css");
const AdminHeader = require("../../components/header/AdminHeader").default;

const CarouselImagesAddPage = () => {
  const s3 = new AWS.S3({
    credentials: {
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_S3_BUCKET_REGION,
    },
  });

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [uploadButton, setUploadButton] = useState(true);

  const listImages = async () => {
    const params = {
      Bucket: "carousel-images-upload-1",
    };

    try {
      const response = await s3.listObjects(params).promise();
      setImages(
        response.Contents.map(
          (item) =>
            `https://carousel-images-upload-1.s3.amazonaws.com/${item.Key}`
        )
      );
    } catch (error) {
      console.error("Error listing images:", error);
    }
  };

  const handleDeleteButtonClick = async (key) => {
    const params = {
      Bucket: "carousel-images-upload-1",
      Key: key,
    };

    try {
      await s3.deleteObject(params).promise();
      setImages((prevImages) =>
        prevImages.filter((image) => !image.includes(key))
      );
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  useEffect(() => {
    listImages();
  }, []);

  const handleImageSelection = async (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    setUploadButton(false);
  };

  const handleUploadButtonClick = async () => {
    if (!selectedFiles.length) {
      toast.error("Select atleast 1 image to upload!");
      return;
    }

    const uploadPromises = selectedFiles.map(async (file) => {
      // Upload the file to the S3 bucket
      const params = {
        Bucket: "carousel-images-upload-1",
        Key: `${Date.now()}-${file.name}`,
        Body: file,
        ContentType: file.type,
        ACL: "public-read",
      };

      try {
        const s3UploadResponse = await s3.upload(params).promise();
        return s3UploadResponse.Location;
      } catch (error) {
        console.error("Error uploading image to S3:", error);
      }
    });

    try {
      const imageUrls = await Promise.all(uploadPromises);
      setImages((prevImages) => [...prevImages, ...imageUrls]);
      toast.success("Image Uploaded Successfully!");
      window.location.reload();
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const renderImages = () => {
    return (
      <div className="d-flex flex-wrap">
        {images.map((image, index) => (
          <div key={index} className="card mt-3" style={{  marginRight: "1rem", width: "30rem", height: "15rem" }}>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-danger"
                onClick={() => handleDeleteButtonClick(image.split("/").pop())}
              >
                X
              </button>
            </div>
            <img
              src={image}
              className="card-img-top"
              alt={`carousel_image_${index}`}
              style={{
                height: "10rem",
                objectFit: "contain",
                objectPosition: "center",
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <AdminHeader />
      <div className="container pt-5 col-12">
        <div className="mt-5 pt-3">
          <div className="card shadow-lg p-3">
            <h1 className="text-center">Carousel Images</h1>
            <form>
              <div className="d-flex justify-content-start">
              <input
                type="file"
                id="image"
                name="image"
                accept="image/*"
                multiple
                onChange={handleImageSelection}
              />
                </div>
            </form>
              <button
                  className="btn btn-primary btn-sm mt-3"
                  onClick={handleUploadButtonClick}
                  disabled={uploadButton}
                  style={{ maxWidth: "250px" }}
              >
                Upload Images
              </button>
            {images.length > 0 && renderImages()}
          </div>
        </div>
      </div>
    </>
  );
};

export default CarouselImagesAddPage;
