import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../../css/CategoryPage.css";
import axios from "axios";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

const CategoryPage = () => {
  const [subCategories, setSubCategories] = useState([]);
  const { category } = useParams();
  const apiUrl = `${process.env.REACT_APP_API}`;
  useEffect(() => {
    axios
      .get(`${apiUrl}/categories/${category}/subcategories`)
      .then((response) => {
        setSubCategories(response.data.subCategories);
      });
  }, [category]);
  return (
    <>
      <Header></Header>
      <div className="container text-center mb-4">
        <div className="row g-4">
          {subCategories.length > 0 &&
            subCategories.map((subCategory, index) => (
              <div
                key={index}
                className="col-12 col-sm-6 col-md-4 col-lg-3 position-relative"
              >
                <Link to={`/${category}/products/${subCategory.slug}`}  className="category-link">
          
                  <div className="row">
                    <div className="col-12 mt-2 mt-sm-2">
                      <div className="d-flex flex-column align-items-center mt-4">
                        <img
                          src={subCategory.image}
                          className="img-thumbnail catimg mx-3"
                          alt={subCategory.name}
                        />
                        <div className="catname">
                          {subCategory.name}
                        </div>
                      </div>
                    </div>
                  </div>

                </Link>
              </div>
            ))}
          {subCategories.length === 0 && (
            <div className="container mt-5">
              <h1>No Categories added</h1>
            </div>
          )}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default CategoryPage;
