import React, { useState, createContext, useContext, useEffect } from "react";
import axios from "axios";
import { useAuth } from "./auth";
import { toast } from "react-hot-toast";
const CartContext = createContext();
const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [auth] = useAuth();
  const local = JSON.parse(localStorage.getItem("auth"));
  const apiUrl = `${process.env.REACT_APP_API}`;
  const userId = auth.currentUser.id;
  useEffect(() => {
    if (userId) {
      const token = local.token;
      axios
        .get(`${apiUrl}/cart`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setCart(response.data);
          localStorage.setItem("cart", JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // }
  }, [userId]);

  useEffect(() => {
    const updateCart = async () => {
      try {
        if (userId) {
          await axios.post(`${apiUrl}/cart/update`, {
            userId,
            cart,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    updateCart();
  }, [cart]);

  const addToCart = (product, selectedSize, quantity, price, availableQty) => {
    let updatedCart = [...cart];

    const existingIndex = updatedCart.findIndex(
      (item) =>
        item.product === product._id && item.selectedSize === selectedSize
    );
    if (existingIndex >= 0) {
      console.log(availableQty);

      if (updatedCart[existingIndex].quantity + 1 > availableQty) {
        toast.error(
          "Last Piece was added to cart. No more same size available !"
        );
        return;
      } else {
        updatedCart[existingIndex].quantity += quantity;
      }
    } else {
      updatedCart.push({
        product: product._id,
        quantity,
        selectedSize,
        price,
        availableQty,
        productName: product.productName,
        productImage: product.images.front,
      });
    }
    toast.success("Product added to cart !");
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  // const incrementItem = (product, size) => {
  //   let updatedCart = [...cart];
  //   const existingIndex = updatedCart.findIndex(
  //     (item) => item.product._id === product._id && item.selectedSize === size
  //   );
  //   updatedCart[existingIndex].quantity += 1;
  //   setCart(updatedCart);
  //   localStorage.setItem("cart", JSON.stringify(updatedCart));
  // };

  const decrementItem = (product, size) => {
    let updatedCart = [...cart];
    const existingIndex = updatedCart.findIndex(
      (item) => item.product === product._id && item.selectedSize === size
    );
    if (updatedCart[existingIndex].quantity > 1) {
      updatedCart[existingIndex].quantity -= 1;
    } else {
      updatedCart.splice(existingIndex, 1);
    }
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const getTotalQuantity = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  const getCartTotal = () => {
    if (!cart || cart.length === 0) {
      return 0;
    }

    const tt = cart.reduce((total, item) => {
      const itemTotal = item.quantity * item.price;

      const tax = 0.06 * itemTotal;
      return total + itemTotal + tax;
    }, 0);

    return tt.toFixed(2);
  };

  const getCartTT = () => {
    if (!cart || cart.length === 0) {
      return 0;
    }

    const tt = cart.reduce(
      (total, item) => total + item.quantity * item.price,
      0
    );
    return tt.toFixed(2);
  };

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem("cart");
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        getTotalQuantity,
        clearCart,
        getCartTotal,
        // incrementItem,
        decrementItem,
        getCartTT,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

const useCart = () => useContext(CartContext);

export { useCart, CartProvider };
