import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "../../css/ProductPage.css";
import { useSearch } from "../../context/searchContext";
import axios from "axios";
import "../../css/ProductPage.css";
import { useAuth } from "../../context/auth";
import { toast } from "react-hot-toast";

export default function Search() {
  const [values] = useSearch();
  const [wishlist, setWishlist] = useState([]);
  const [auth] = useAuth();

  const apiUrl = `${process.env.REACT_APP_API}`;
  const fetchWishlist = async () => {
    try {
      const userId = auth.currentUser.id;
      if (userId) {
        const response = await axios.get(`${apiUrl}/wishlist`);
        setWishlist(response.data.products);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchWishlist();
  }, [auth.currentUser.id]);
  const isInWishlist = (productId) => {
    return wishlist.some((item) => item._id === productId);
  };

  const addToWishlist = async (productId) => {
    try {
      fetchWishlist();
      const response = await axios.post(`${apiUrl}/wishlist/add`, {
        productId,
      });
      setWishlist([...wishlist, response.data.products]);
      toast.success("Added to Wishlist");
      await fetchWishlist();
    } catch (error) {
      console.error(error);
    }
  };
  const removeFromWishlist = async (productId) => {
    try {
      if (!auth) {
        return;
      }
      await axios.delete(`${apiUrl}/wishlist/delete/${productId}`);
      const updatedWishlist = wishlist.filter(
        (products) => products._id !== productId
      );
      setWishlist(updatedWishlist);
      toast.success("Removed from Wishlist");
      await fetchWishlist();
    } catch (err) {
      console.error(err.message);
    }
  };
  return (
    <>
      <Header />
      <div className="container mt-5 box">
        <h1 style={{ fontWeight: "400" }}> Search Results</h1>
        <h2 style={{ paddingBottom: "20px", fontWeight: "300" }}>
          {" "}
          {values.results.length < 1
            ? `No product found for your requested search`
            : `Found ${values.results.length} products`}{" "}
        </h2>

        <div className="product-list row row-cols-1 row-cols-md-4 g-4">
          {values.results.length > 0 &&
            values.results.map((product) => (
              <div className="product col-6 col-md-3" key={product.id}>
                <div className="card h-100">
                  <div className="position-relative">
                    <Link
                      to={`/products/${product._id}`}
                      className="text-decoration-none"
                    >
                      <img
                        src={product.images.front}
                        alt={product.name}
                        className="card-img-top img-fluid image-container"
                        // style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                        style={{
                          Width: "100%",
                          objectFit: "cover",
                          objectPosition: "center center",
                        }}
                        loading="lazy"
                      />
                      {product.salePrice > 0 && (
                        <span className="off">
                          {Math.round(
                            ((product.productPrice - product.salePrice) /
                              product.productPrice) *
                              100
                          )}
                          % off
                        </span>
                      )}
                    </Link>
                    <button
                      className={`position-absolute bottom-0 end-0 btn ${
                        isInWishlist(product._id)
                          ? "heart-buttonSelected"
                          : "heart-buttonUnselected"
                      }`}
                      onClick={() => {
                        if (!localStorage.getItem("auth")) {
                          toast.error("You're not logged in");
                          return;
                        }
                        if (isInWishlist(product._id)) {
                          removeFromWishlist(product._id);
                        } else {
                          addToWishlist(product._id);
                        }
                      }}
                    >
                      <i className="fas fa-heart fa-2x"></i>
                    </button>
                  </div>

                  <div className="card-body d-flex flex-column align-items-start">
                    <h4 className="card-title card-title-design">
                      {product.productName}
                    </h4>
                    <div class="divider" />
                    <div className="d-flex justify-content-between align-items-center w-100 pt-3">
                      <div>
                        {product.salePrice > 0 ? (
                          <p className="card-text priceTag flex-grow-0">
                            <span className="salePrice">
                              ${product.salePrice}
                            </span>
                            <span className="oldPrice">
                              ${product.productPrice}
                            </span>
                          </p>
                        ) : (
                          <p className="card-text priceTag flex-grow-0">
                            ${product.productPrice}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
