import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/auth";
import Header from "../../components/header/Header";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import PopUp from "../../components/PopUp";

const UserProfilePage = () => {
  const [auth, setAuth] = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [currPassword, setCurrPassword] = useState("");
  const [email, setEmail] = useState("");
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [originalState, setOriginalState] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = !!auth.token;

  useEffect(() => {
    if (auth.currentUser) {
      const { name, email, address, phone } = auth.currentUser;
      setEmail(email);
      setFirstName(name.first);
      setLastName(name.last);
      setStreet1(address.street1);
      setStreet2(address.street2);
      setCity(address.city);
      setOriginalState(address.state);
      setZip(address.zip);
      setPhone(phone);
    }
  }, [auth.currentUser.id]);
  const apiUrl = `${process.env.REACT_APP_API}`;

  const handleSubmit = async (event) => {
    event.preventDefault();
    const updatedData = {
      firstName,
      lastName,
      email,
      street1,
      street2,
      city,
      originalState,
      zip,
      phone,
      password,
      currPassword,
    };
    try {
      const { data } = await axios.put(`${apiUrl}/user`, updatedData);
      if (data.error) {
        console.log(data);
        toast.error(data.error);
      } else {
        console.log(data);
        setAuth({ ...auth, user: data });
        let ls = localStorage.getItem("auth");
        ls = JSON.parse(ls);
        ls.currentUser = data;
        localStorage.setItem("auth", JSON.stringify(ls));
        toast.success("Profile Updated");
        navigate(location.state || "/", { replace: true });
      }
    } catch (error) {
      console.log(error);
      if (!currPassword) {
        setShowPopUp(true);
      } else {
        toast.error("Incorrect Current Password.");
      }
    }
  };

  return (
    <>
      <Header />
      {showPopUp && (
        <PopUp
          loginButtonVisible={false}
          onCloseClick={() => {
            setShowPopUp(false);
          }}
        >
          Please Provide Current Password to Update.
        </PopUp>
      )}
      {isLoggedIn ? (
        <>
          <div className="container mt-5 mb-5">
            <h1
              className="d-flex justify-content-center"
              style={{ marginTop: "15px", fontWeight: "450" }}
            >
              My Profile
            </h1>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Current Password * (Required)
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="currPassword"
                  onChange={(event) => setCurrPassword(event.target.value)}
                />
              </div>

              {/* New Password */}
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  New Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="street1" className="form-label">
                  Street Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="street1"
                  value={street1}
                  onChange={(event) => setStreet1(event.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="street2" className="form-label">
                  Street Address 2
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="street2"
                  value={street2}
                  onChange={(event) => setStreet2(event.target.value)}
                />
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    value={city}
                    onChange={(event) => setCity(event.target.value)}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="state" className="form-label">
                    State
                  </label>
                  <select
                    className="form-control"
                    id="state"
                    value={originalState}
                    onChange={(event) => setOriginalState(event.target.value)}
                  >
                    <option value="">State</option>
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-2">
                  <label htmlFor="zip" className="form-label">
                    Zip
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="zip"
                    value={zip}
                    onChange={(event) => setZip(event.target.value)}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="street1" className="form-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                />
              </div>
              <button type="submit" className="btn button">
                Update
              </button>
            </form>
          </div>
        </>
      ) : (
        <div className="continer mt-5">
          <div className="d-flex justify-content-center">
            <p>You need to be logged in to view contents</p>
            <br />
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn add-to-cart"
              onClick={() => navigate("/login")}
            >
              Click to login
            </button>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default UserProfilePage;
