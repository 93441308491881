import React, { useState,useEffect } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "../../css/ReviewForm.css";
import { toast } from "react-hot-toast";
import {useAuth} from "../../context/auth";
import {useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons'

function Reviews() {

    const [reviews, setReviews] = useState([]);
    const apiUrl = `${process.env.REACT_APP_API}`;

    useEffect(() => {
        const fetchReviews = async () => {
          try {
            const response = await fetch(`${apiUrl}/reviews`);
            const data = await response.json();
            setReviews(data.data);
          } catch (error) {
            console.error(error);
          }
        };
        fetchReviews();
      }, []);

      const renderStars = (rating) => {
        const fullStars = Math.floor(rating);
        
    
        const stars = [];
        for (let i = 0; i < fullStars; i++) {
          stars.push(<FontAwesomeIcon icon={faStar} key={i} />);
        }
    
        
    
        return stars;
      };

    return (
        <>
            <Header></Header>

            <div className="container d-flex justify-content-center mt-5">
                <div>
                <div className="d-flex justify-content-center mb-3" >
                
                <h1>Reviews</h1>
                </div>
                <ol>
                    {reviews.map((review, index) => (
                        <li key={index}>
                        {Array.from({ length: review.rating }, (_, i) => (
                            <FontAwesomeIcon icon={faStar} key={i} style={{ color: '#FECB38' }} />
                        ))}
                        
                        
                        <p style={{fontWeight:"350"}}>{review.comment}- <b><i>{review.name}</i></b></p>
                        <hr></hr>
                        </li>
                        
                    
                    ))}
                </ol>

            </div>
            </div>

            

            <Footer></Footer>
            
        </>
    );
}

export default Reviews;
