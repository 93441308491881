import React from "react";
import "../css/PopUp.css";

const Popup = ({
  children,
  loginButtonVisible,
  onLoginClick,
  onCloseClick,
}) => {
  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup card">
          <div className="card-header">
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={onCloseClick}
            ></button>
          </div>
          <div className="card-body">
            <p>{children}</p>
            {loginButtonVisible && (
              <button className="btn button " onClick={onLoginClick}>
                Login
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
