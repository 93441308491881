import React, { useState } from "react";
import Header from "../../components/header/Header";
import "../../css/ReviewForm.css";
import { toast } from "react-hot-toast";
import {useAuth} from "../../context/auth";
import {useNavigate} from "react-router-dom";

function ReviewForm() {
    const [rating, setRating] = useState(0);
    const [hoverRating, setHoverRating] = useState(0);
    const [comment, setComment] = useState('');
    const [auth] = useAuth();
    const navigate = useNavigate();
    const isLoggedIn = !!auth.token;
    const apiUrl = `${process.env.REACT_APP_API}`;
    
    
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        const name = auth.currentUser.name.first+" "+ auth.currentUser.name.last;


        try {
            const response = await fetch(`${apiUrl}/review`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ rating, comment, name })
            });
            if(response.ok){
                toast.success("Thank you for your review!");
                navigate("/")
            }

            if (!response.ok) {
                console.log(response);
                toast.error("Could not submit review");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleStarMouseEnter = (index) => {
        setHoverRating(index + 1);
    };

    const handleStarMouseLeave = () => {
        setHoverRating(0);
    };

    const handleStarClick = (index) => {
        setRating(index + 1);
    };

    return (
        <>
            <Header></Header>
            {isLoggedIn ? (<><div className="container pt-5 col-12">
                <div className="mt-5 pt-3">
                    <div className="card shadow-lg p-3">
                        <h3 className="text-center">Leave a Review!</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="form-container">
                                <label htmlFor="rating" className="col-sm-4 col-form-label">
                                    Rating:
                                </label>
                                <div className="star-rating col-sm-8">
                                    <i
                                        className={`fas fa-star ${hoverRating >= 1 || rating >= 1 ? "selected" : ""}`}
                                        onMouseEnter={() => handleStarMouseEnter(0)}
                                        onMouseLeave={handleStarMouseLeave}
                                        onClick={() => handleStarClick(0)}
                                    ></i>
                                    <i
                                        className={`fas fa-star ${hoverRating >= 2 || rating >= 2 ? "selected" : ""}`}
                                        onMouseEnter={() => handleStarMouseEnter(1)}
                                        onMouseLeave={handleStarMouseLeave}
                                        onClick={() => handleStarClick(1)}
                                    ></i>
                                    <i
                                        className={`fas fa-star ${hoverRating >= 3 || rating >= 3 ? "selected" : ""}`}
                                        onMouseEnter={() => handleStarMouseEnter(2)}
                                        onMouseLeave={handleStarMouseLeave}
                                        onClick={() => handleStarClick(2)}
                                    ></i>
                                    <i
                                        className={`fas fa-star ${hoverRating >= 4 || rating >= 4 ? "selected" : ""}`}
                                        onMouseEnter={() => handleStarMouseEnter(3)}
                                        onMouseLeave={handleStarMouseLeave}
                                        onClick={() => handleStarClick(3)}
                                    ></i>
                                    <i
                                        className={`fas fa-star ${hoverRating >= 5 || rating >= 5 ? "selected" : ""}`}
                                        onMouseEnter={() => handleStarMouseEnter(4)}
                                        onMouseLeave={handleStarMouseLeave}
                                        onClick={() => handleStarClick(4)}
                                    ></i>
                                </div>
                            </div>
                            <div className="form-container">
                                <label htmlFor="comment" className="col-sm-4 col-form-label">
                                    Comment:
                                </label>
                                <textarea
                                    className="col-sm-8"
                                    id="comment"
                                    value={comment}
                                    onChange={(event) => setComment(event.target.value)}
                                    style={{height:"100px"}}
                                ></textarea>
                            </div>
                            <div className="form-container">
                                <div className="col-sm-8"></div>
                                <button
                                    type="submit"
                                    className="btn btn-outline-primary"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div></>):(<div className="continer mt-5">
                <div className="d-flex justify-content-center">
                    <p>You need to be logged in to view contents</p><br/>
                </div>
                <div className="d-flex justify-content-center">
                    <button className="btn add-to-cart" onClick={()=> navigate("/login")}>Click to login</button>
                </div>
            </div>)}
        </>
    );
}

export default ReviewForm;
