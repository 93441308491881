import React, { useEffect, useState } from "react";
import "../../css/CategoryPage.css";
import axios from "axios";

import { useAuth } from "../../context/auth";
import AdminHeader from "../../components/header/AdminHeader";

const Analytics = () => {
  const [auth] = useAuth();
  const [orders, setOrders] = useState([]);
  const [users, setUsers] = useState([]);
  const [topBuyers, setTopBuyers] = useState([]);
  const [activeCart, setActiveCart] = useState(0);

  useEffect(() => {
    if (auth.token) {
      getOrders();
      getUsers();
      getActiveCart();
    }
  }, [auth.token]);

  const getActiveCart = async () => {
    const response = await axios.get(`${apiUrl}/cart/active-cart`);
    setActiveCart(response.data.totalActiveCarts);
  };

  const apiUrl = `${process.env.REACT_APP_API}`;
  const getOrders = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/all-orders`);

      setOrders(data);
    } catch (err) {
      console.log(err);
    }
  };
  const getUsers = async () => {
    try {
      const dat = await axios.get(`${apiUrl}/all-users`);

      setUsers(dat);
    } catch (err) {
      console.log(err);
    }
  };

  function calculateTotalSale(orders) {
    let totalSale = 0;
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      totalSale += parseFloat(order.total);
    }
    return totalSale.toFixed(2);
  }

  function getTotalUsers(users) {
    return users.data;
  }

  //TOP 3 BUYER
  function getTopBuyers(orders) {
    const buyers = {};

    // calculate total order amounts for each buyer
    orders.forEach((order) => {
      const buyerId = order.buyer._id;
      const orderTotal = order.total;

      if (buyers[buyerId]) {
        buyers[buyerId].total += parseFloat(orderTotal);
      } else {
        buyers[buyerId] = {
          id: buyerId,
          name: order.buyer.name,
          email: order.buyer.email,
          phone: order.buyer.phone,
          address: order.buyer.address,
          total: parseFloat(orderTotal),
        };
      }
    });

    // sort buyers by total order amounts in descending order
    const sortedBuyers = Object.values(buyers).sort(
      (a, b) => b.total - a.total
    );

    // return top 3 buyers
    return sortedBuyers.slice(0, 3);
  }

  useEffect(() => {
    async function fetchData() {
      const result = await getTopBuyers(orders);
      setTopBuyers(result);
    }

    fetchData();
  }, [orders]);

  const totalSaleTillDate = calculateTotalSale(orders);

  const dd = getTotalUsers(users);

  return (
    <>
      <AdminHeader></AdminHeader>

      <div className="container mb-4">
        <h1
          className="mt-4 d-flex justify-content-center"
          style={{
            color: "#002E6A",
            marginTop: "15px",

            fontWeight: "350",
          }}
        >
          {" "}
          Analytics{" "}
        </h1>

        <div className="card mt-5">
          <div className="card-body">
            <h5 className="card-text" style={{ color: "#002e6a" }}>
              Finances
            </h5>
            <hr className="mt-1" />

            <h6>Total Sale: ${totalSaleTillDate} </h6>
            <h6>
              Total Taxes Collected: $
              {(totalSaleTillDate - totalSaleTillDate / 1.053).toFixed(2)}
            </h6>
          </div>
        </div>
        <div className="card mt-5">
          <div className="card-body">
            <h5 className="card-text" style={{ color: "#002e6a" }}>
              Users
            </h5>
            <hr className="mt-1" />

            <h6>Total Users: {dd}</h6>
            <h6>Total Orders: {orders.length}</h6>
            <h6>Active Carts: {activeCart}</h6>
          </div>
        </div>
        <div className="card mt-5">
          <div className="card-body">
            <h5 className="card-text" style={{ color: "#002e6a" }}>
              Top Buyers
            </h5>
            <hr className="mt-1" />

            <ol>
              {topBuyers.map((buyer) => (
                <li key={buyer.id}>
                  {buyer.name.first} {buyer.name.last} -{" "}
                  <ul style={{ fontSize: "12px" }}>
                    {" "}
                    <li>${buyer.total}</li> <li>{buyer.email}</li>{" "}
                    <li>{buyer.phone} </li>{" "}
                    <li>
                      {buyer.address.street1},{buyer.address.street2},
                      {buyer.address.city},{buyer.address.state}-
                      {buyer.address.zip}
                    </li>
                  </ul>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};
export default Analytics;
