import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../css/ProductAddPage.css";
import { useNavigate } from "react-router-dom";

import AdminHeader from "../../components/header/AdminHeader";
import { toast } from "react-hot-toast";
function ProductAddPage() {
  const navigate = useNavigate();
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  // const [salePrice, setSalePrice] = useState("");
  const [productSize, setProductSize] = useState({
    sizes: [{ label: "", quantity: "" }],
  });
  const [productDescription, setProductDescription] = useState("");
  const [frontImageFile, setFrontImageFile] = useState(null);
  const [rightImageFile, setRightImageFile] = useState(null);
  const [leftImageFile, setLeftImageFile] = useState(null);
  const [backImageFile, setBackImageFile] = useState(null);
  const [frontImage, setFrontImage] = useState("");
  const [rightImage, setRightImage] = useState("");
  const [leftImage, setLeftImage] = useState("");
  const [backImage, setBackImage] = useState("");
  const [productCategory, setProductCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [featured, setFeatured] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const apiUrl = `${process.env.REACT_APP_API}`;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const onFileChange1 = (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      setFrontImageFile(file);
      console.log(frontImageFile);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFrontImageFile(file);
        setFrontImage(reader.result);
      };
    } else {
      setFrontImageFile(null);
      setFrontImage("");
    }
  };
  const onFileChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLeftImageFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setLeftImageFile(file);
        setLeftImage(reader.result);
      };
    } else {
      setLeftImageFile(null);
      setLeftImage("");
    }
  };

  const onFileChange3 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setRightImageFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setRightImageFile(file);
        setRightImage(reader.result);
      };
    } else {
      setRightImageFile(null);
      setRightImage("");
    }
  };

  const onFileChange4 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBackImageFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setBackImageFile(file);
        setBackImage(reader.result);
      };
    } else {
      setBackImageFile(null);
      setBackImage("");
    }
  };

  const onSizeChange = (event, index) => {
    const { name, value } = event.target;
    const sizes = [...productSize.sizes];
    sizes[index][name] = value;
    setProductSize({
      ...productSize,
      sizes,
    });
  };

  const handleAddSize = () => {
    setProductSize({
      ...productSize,
      sizes: [...productSize.sizes, { label: "", quantity: "" }],
    });
  };

  const handleRemoveSize = () => {
    const newSizes = [...productSize.sizes];
    newSizes.pop();
    setProductSize({ ...productSize, sizes: newSizes });
  };

  useEffect(() => {
    console.log(selectedCategory);
    if (
      selectedCategory.toString() === "6413790302e7ee19ff5c8326" ||
      selectedCategory.toString() === "641378fd02e7ee19ff5c8323" ||
      selectedCategory.toString() === "6413790602e7ee19ff5c8329" ||
      selectedCategory.toString() === "6413790e02e7ee19ff5c832c"
    ) {
      axios
        .get(`${apiUrl}/subcategories/${selectedCategory}`)
        .then((response) => {
          setSubCategory(response.data.subCategories);
        });
    } else {
      setSubCategory([]);
    }
    setSelectedSubCategory("");
  }, [selectedCategory]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/categories`)
      .then((response) => {
        setProductCategory(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    setSelectedCategory("");
    console.log(selectedCategory);
  }, []);

  const validateForm = () => {
    let errors = {};

    if (!productName.trim()) {
      errors.productName = "Name is required";
    } else if (productName.length < 3 || productName.length > 50) {
      errors.productName = "Name must be between 3 and 50 characters";
    }

    if (!productPrice) {
      errors.productPrice = "Price is required";
    } else if (!/^\d+(\.\d{1,2})?$/.test(productPrice)) {
      errors.productPrice = "Price must be a valid number";
    } else if (isNaN(productPrice) || productPrice <= 0) {
      errors.productPrice = "Please enter a valid price";
    }

    if (!selectedCategory) {
      errors.productCategory = "Category is required";
    }

    if (
      (selectedCategory === "6413790302e7ee19ff5c8326" ||
        selectedCategory === "641378fd02e7ee19ff5c8323" ||
        selectedCategory === "6413790602e7ee19ff5c8329" ||
        selectedCategory === "6413790e02e7ee19ff5c832c") &&
      !selectedSubCategory
    ) {
      errors.subCategory = "Subcategory is required";
    }

    if (!frontImage) {
      errors.frontImage = "Front Image is required";
    }

    if (!leftImage) {
      errors.leftImage = "Left Image is required";
    }

    if (!rightImage) {
      errors.rightImage = "Right Image is required";
    }

    if (!backImage) {
      errors.backImage = "Back Image is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const improveDescription = () => {
    // Make an API request to your backend to improve the product description
    axios
      .post(`${apiUrl}/improve-description`, {
        productDescription: productDescription,
      })
      .then((response) => {
        // Update the state with the improved description
        setProductDescription(response.data.improvedDescription);
        console.log(response.data.improvedDescription);
      })
      .catch((error) => {
        console.error("Error improving description:", error);
      });
  };

  async function handleProductSubmit(event) {
    event.preventDefault();

    console.log(validateForm());

    if (validateForm()) {
      const formData = new FormData();
      formData.append("productName", productName);
      formData.append("productPrice", productPrice);
      formData.append("productSize", JSON.stringify(productSize));
      formData.append("productDescription", productDescription);
      formData.append("isFeatured", featured);
      formData.append("productCategory", selectedCategory);
      formData.append("subCategory", selectedSubCategory);
      formData.append("front", frontImageFile);
      formData.append("left", leftImageFile);
      formData.append("right", rightImageFile);
      formData.append("back", backImageFile);

      toast
        .promise(axios.post(`${apiUrl}/product`, formData), {
          loading: "Adding Product...",
          success: "Product Added Successfully!",
          error: "Product could not be added.",
        })
        .then((response) => {
          console.log("Success:", response);
          navigate("/admin/product/view");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  return (
    <>
      <AdminHeader />
      <div className="container pt-5 ">
        <div className="mt-5 pt-3">
          <div className="card shadow-lg p-3">
            <h1 className="text-center">Add New Product</h1>
            <form onSubmit={handleProductSubmit} encType="multipart/form-data">
              <div className="row mb-3">
                <label
                  htmlFor="productName"
                  className="col-sm-4 col-form-label"
                >
                  Name of the Product
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="productName"
                    value={productName}
                    onChange={(event) => {
                      setProductName(event.target.value);
                    }}
                  />
                  {formErrors.productName && (
                    <span className="text-danger">
                      {formErrors.productName}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="productPrice"
                  className="col-sm-4 col-form-label"
                >
                  Price
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="productPrice"
                    value={productPrice}
                    onChange={(event) => {
                      setProductPrice(event.target.value);
                    }}
                  />
                  {formErrors.productPrice && (
                    <span className="text-danger">
                      {formErrors.productPrice}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="productSize"
                  className="col-sm-4 col-form-label"
                >
                  Product Sizes
                </label>
                <div className="col-sm-8 d-flex flex-wrap align-items-start">
                  {productSize.sizes.map((size, index) => (
                    <div
                      key={index}
                      className="me-2 mb-2"
                      style={{ flexBasis: "50%" }}
                    >
                      <div className="row">
                        <div className="col">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={`Label`}
                            name="label"
                            value={size.label}
                            onChange={(e) => onSizeChange(e, index)}
                          />
                        </div>
                        <div className="col">
                          <input
                            type="number"
                            className="form-control"
                            placeholder={`Qty`}
                            name="quantity"
                            value={size.quantity}
                            onChange={(e) => {
                              onSizeChange(e, index);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="col-sm-12 d-flex">
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={handleAddSize}
                    >
                      Add Size
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary ms-3"
                      onClick={handleRemoveSize}
                    >
                      Remove Size
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="categoryGender"
                  className="col-sm-4 col-form-label"
                >
                  Category
                </label>
                <div className="col-sm-8">
                  <select
                    className="form-select"
                    id="categoryGender"
                    value={selectedCategory}
                    onChange={(event) => {
                      setSelectedCategory(event.target.value);
                    }}
                  >
                    <option value="">Select a category</option>
                    {productCategory.map((data) => (
                      <option key={data._id} value={data._id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                  {formErrors.productCategory && (
                    <span className="text-danger">
                      {formErrors.productCategory}
                    </span>
                  )}
                </div>
              </div>
              {/* Conditionally render the second dropdown */}
              {selectedCategory.toString() === "6413790302e7ee19ff5c8326" ||
              selectedCategory.toString() === "641378fd02e7ee19ff5c8323" ||
              selectedCategory.toString() === "6413790602e7ee19ff5c8329" ||
              selectedCategory.toString() === "6413790e02e7ee19ff5c832c" ? (
                <div className="row mb-3">
                  <label
                    htmlFor="productType"
                    className="col-sm-4 col-form-label"
                  >
                    Subcategory
                  </label>
                  <div className="col-sm-8">
                    <select
                      className="form-select"
                      id="productType"
                      value={selectedSubCategory}
                      onChange={(event) => {
                        setSelectedSubCategory(event.target.value);
                      }}
                    >
                      <option value="">Select a subcategory</option>
                      {subCategory.map((data) => (
                        <option key={data._id} value={data._id}>
                          {data.name}
                        </option>
                      ))}
                    </select>
                    {formErrors.subCategory && (
                      <span className="text-danger">
                        {formErrors.subCategory}
                      </span>
                    )}
                  </div>
                </div>
              ) : null}

              <div className="row mb-3">
                <label
                  htmlFor="productDescription"
                  className="col-sm-4 col-form-label"
                >
                  Description
                </label>
                <div className="col-sm-8">
                  <textarea
                    className="form-control"
                    id="productDescription"
                    rows="auto"
                    value={productDescription}
                    onChange={(event) =>
                      setProductDescription(event.target.value)
                    }
                    placeholder="Original Product Description"
                    style={{ minHeight: "170px" }}
                  ></textarea>
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-4 col-form-label"></label>
                <div className="col-sm-8">
                  <button
                    type="button"
                    className="btn button"
                    onClick={improveDescription}
                  >
                    Make it better
                  </button>
                </div>
              </div>

              <div className="row mb-3">
                <label htmlFor="featured" className="col-sm-4 col-form-label">
                  Featured
                </label>
                <div className="col-sm-8">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="featured"
                      id="featuredYes"
                      value="yes"
                      checked={featured === "yes"}
                      onChange={(event) => setFeatured(event.target.value)}
                    />
                    <label className="form-check-label" htmlFor="featuredYes">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="featured"
                      id="featuredNo"
                      value="no"
                      checked={featured === "no"}
                      onChange={(event) => setFeatured(event.target.value)}
                    />
                    <label className="form-check-label" htmlFor="featuredNo">
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <label
                  htmlFor="front-image"
                  className="col-sm-4 col-form-label"
                >
                  Front Image
                </label>
                {frontImageFile && (
                  <img
                    src={frontImage}
                    className="img-"
                    alt="imageUrl"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      objectPosition: "center center",
                      marginBottom: "5px",
                      marginLeft: "10px",
                    }}
                  />
                )}
                <div className="col-sm-8">
                  <input
                    type="file"
                    className="form-control-file"
                    id="frontImage"
                    name="frontImage"
                    accept="image/*"
                    onChange={onFileChange1}
                  />
                </div>
                {formErrors.frontImage && (
                  <span className="text-danger">{formErrors.frontImage}</span>
                )}
              </div>

              <div className="row mb-3">
                <label htmlFor="left-image" className="col-sm-4 col-form-label">
                  Left Image
                </label>
                {leftImageFile && (
                  <img
                    src={leftImage}
                    className="img-"
                    alt="imageUrl"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      objectPosition: "center center",
                      marginBottom: "5px",
                      marginLeft: "10px",
                    }}
                  />
                )}
                <div className="col-sm-8">
                  <input
                    type="file"
                    className="form-control-file"
                    id="leftImage"
                    name="leftImage"
                    accept="image/*"
                    onChange={onFileChange2}
                  />
                </div>
                {formErrors.leftImage && (
                  <span className="text-danger">{formErrors.leftImage}</span>
                )}
              </div>

              <div className="row mb-3">
                <label
                  htmlFor="right-image"
                  className="col-sm-4 col-form-label"
                >
                  Right Image
                </label>
                {rightImageFile && (
                  <img
                    src={rightImage}
                    className="img-"
                    alt="imageUrl"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      objectPosition: "center center",
                      marginBottom: "5px",
                      marginLeft: "10px",
                    }}
                  />
                )}
                <div className="col-sm-8">
                  <input
                    type="file"
                    className="form-control-file"
                    id="rightImage"
                    accept="image/*"
                    name="rightImage"
                    onChange={onFileChange3}
                  />
                </div>
                {formErrors.rightImage && (
                  <span className="text-danger">{formErrors.rightImage}</span>
                )}
              </div>

              <div className="row mb-3">
                <label htmlFor="back-image" className="col-sm-4 col-form-label">
                  Back Image
                </label>
                {backImageFile && (
                  <img
                    src={backImage}
                    className="img-"
                    alt="imageUrl"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      objectPosition: "center center",
                      marginBottom: "5px",
                      marginLeft: "10px",
                    }}
                  />
                )}
                <div className="col-sm-8">
                  <input
                    type="file"
                    className="form-control-file"
                    id="backImage"
                    name="backImage"
                    accept="image/*"
                    onChange={onFileChange4}
                  />
                </div>
                {formErrors.backImage && (
                  <span className="text-danger">{formErrors.backImage}</span>
                )}
              </div>

              <div className="row mb-3">
                <label
                  htmlFor="submitButton"
                  className="col-sm-4 col-form-label"
                ></label>
                <div className="col-sm-8">
                  <button type="submit" className="btn button mt-3">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductAddPage;
