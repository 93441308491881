import React, { useState } from "react";
import axios from "axios";
import { useSearch } from "../context/searchContext";
import { useNavigate } from "react-router-dom";

export default function Search() {
  const [keyword, setKeyWord] = useState("");
  const navigate = useNavigate();

  const [values, setValues] = useSearch();

  const filterProductByQuantity = (products) => {
    return products.filter((p) => {
      return p.productSize.sizes.some((size) => size.quantity > 0);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(`/products/search/${keyword}`);

      setValues({ ...values, results: filterProductByQuantity(data) });
      navigate("/search");
      window.scrollTo({top: 0, behavior: 'instant'})
    } catch (err) {
      console.log(err);
    }
  };

 

  return (
    <form
      className="d-flex justify-content-center col-sm-6 col-md-7 col-lg-8 col-xl-10 "
      role="search"
      onSubmit={handleSubmit}
    >
      <div className="input-group">
        <input
          className="form-control border-end-0 border"
          type="search"
          placeholder="Search"
          aria-label="Search"
          onChange={(e) => setKeyWord(e.target.value)}
        />
        <button className="btn btn-outline-secondary button" type="submit">
          <i className="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </form>
  );
}
