import React, { useEffect, useState, useRef } from "react";
import AdminHeader from "../../components/header/AdminHeader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { NavLink } from "react-router-dom";
import "../../css/ProductAddPage.css";
import { toast } from "react-hot-toast";

const ProductViewPage = () => {
  const [getProductData, setProductData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");

  const topRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25; // Number of products per page

  useEffect(() => {
    getData();
  }, []);

  const apiUrl = `${process.env.REACT_APP_API}`;

  useEffect(() => {
    const getCategories = async () => {
      const res = await fetch(`${apiUrl}/categories`, {
        method: "GET",
      });
      const data = await res.json();
      setCategories(data);
    };
    getCategories();
  }, []);

  const getSubcategories = async (categoryName) => {
    const res = await fetch(
      `${apiUrl}/categories/${categoryName}/subcategories`,
      {
        method: "GET",
      }
    );
    const data = await res.json();
    setSubcategories(data.subCategories);
  };

  const getProducts = async (subcategoryName) => {
    if (selectedCategory && subcategoryName) {
      const res = await fetch(
        `${apiUrl}/categories/${selectedCategory}/subcategory/${subcategoryName}/products`,
        {
          method: "GET",
        }
      );
      const data = await res.json();
      setProductData(data);
    }
  };

  const getData = async (e) => {
    try {
      const res = await fetch(`${apiUrl}/products`, {
        method: "GET",
      });
      const data = await res.json();

      setProductData(data);
    } catch (err) {
      console.log("error");
    }
  };

  const handleCategoryChange = async (e) => {
    const categoryName = e.target.value;
    setSelectedCategory(categoryName);
    getSubcategories(categoryName);
  };

  const handleSubcategoryChange = (e) => {
    const subcategoryName = e.target.value;
    setSelectedSubcategory(subcategoryName);
    setCurrentPage(1);
    getProducts(subcategoryName);
  };

  useEffect(() => {
    getProducts();
  }, [currentPage]);

  const deleteProduct = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this product?"
    );

    if (confirmDelete) {
      const res2 = await fetch(`${apiUrl}/product/${id}`, {
        method: "DELETE",
      });

      const deleteProduct = await res2.json();

      if (res2.status === 422 || !deleteProduct) {
        console.log("error");
      } else {
        toast.success("Product Deleted!");
        getData();
      }
    }
  };

  const indexOfLastOrder = currentPage * itemsPerPage;
  const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
  const currentOrders = getProductData.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(getProductData.length / itemsPerPage);
  // Create an array of page numbers from 1 to totalPages
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (selectedCategory && selectedSubcategory) {
      getProducts(selectedSubcategory);
    }
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <>
      <AdminHeader></AdminHeader>
      <div ref={topRef}></div>
      <div className="container mt-5 box">
        <div className="form-group" style={{ width: "100vw" }}>
          {/* ... Category and subcategory select elements ... */}
          <label htmlFor="category">Category</label>
          <select
            className="form-control"
            style={{ width: "40%" }}
            id="category"
            onChange={handleCategoryChange}
            value={selectedCategory}
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.name} value={category.slug}>
                {category.name}
              </option>
            ))}
          </select>
          {selectedCategory && (
            <div className="form-group">
              <label htmlFor="subcategory">Subcategory</label>
              <select
                className="form-control"
                style={{ width: "30%" }}
                id="subcategory"
                onChange={handleSubcategoryChange}
                value={selectedSubcategory}
              >
                <option value="">Select a subcategory</option>
                {subcategories.map((subcategory) => (
                  <option key={subcategory.name} value={subcategory.slug}>
                    {subcategory.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {/*  */}
        </div>
        <div className="row mb-3">
          <table className="table">
            <thead>
              <tr className="table-dark">
                <th scope="col">Image</th>
                <th scope="col">Product Name</th>
                <th scope="col">Update/Delete</th>
              </tr>
            </thead>
            <tbody>
              {currentOrders.map((element, index) => {
                // Your product rendering logic here
                const frontImage = element.images && element.images.front;
                return (
                  <tr key={element._id}>
                    <td>
                      <img
                        src={frontImage}
                        className="img-"
                        alt={element.productName}
                        style={{
                          width: "150px",
                          height: "150px",
                          objectFit: "contain",
                          objectPosition: "center",
                        }}
                        loading="lazy"
                      />
                    </td>
                    <td>{element.productName}</td>
                    <td key={index}>
                      <NavLink to={`/admin/product/update/${element._id}`}>
                        <button className="btn btn-primary">
                          <EditIcon />
                        </button>
                      </NavLink>
                      <button
                        className="btn btn-danger"
                        onClick={() => deleteProduct(element._id)}
                      >
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                );
                //
              })}
            </tbody>
          </table>
        </div>

        {/* Pagination controls with page numbers */}
        <div className="pagination">
          {currentPage > 1 && (
            <button onClick={() => handlePageChange(currentPage - 1)}>
              Prev
            </button>
          )}
          {/* {pageNumbers.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              className={currentPage === pageNumber ? "active" : ""}
            >
              {pageNumber}
            </button>
          ))} */}
          <button
            key={currentPage}
            onClick={() => handlePageChange(currentPage)}
            className={currentPage === currentPage ? "active" : ""}
          >
            {currentPage} /
            <span className="pagination-text"> {totalPages}</span>
          </button>
          {currentPage + 1 <= totalPages && (
            <button onClick={() => handlePageChange(currentPage + 1)}>
              Next
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductViewPage;
