import React, { useState } from "react";
import "../../css/Login.css";
import logo from "../../components/images/logo-transparent-png.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";

const AdminLoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [touchedFields, setTouchedFields] = useState({});
  const [auth, setAuth] = useAuth();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setTouchedFields({ ...touchedFields, email: true });
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const apiUrl = `${process.env.REACT_APP_API}`;

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/admin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // Save token to local storage
        localStorage.setItem("auth", JSON.stringify(data));
        setAuth({ ...auth, token: data.token, user: data.user });
        console.log(auth.user);
        toast.success("Successful Login");
        // Redirect to dashboard page
        navigate(location.state || "/admin/dashboard", { replace: true });
      } else {
        // Handle login error, e.g. display error message to user
        console.error(data.message);
        toast.error("Invalid Email/Password");
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle login error, e.g. display error message to user
      console.error("An error occurred while trying to login.");
    }
  };
  const validateFormData = (formData) => {
    const errors = {};
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email format";
    }
    return errors;
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const errors = validateFormData({ email });

  return (
    <>
      <div className="container mt-5 mb-5">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-8 p-5 ">
            <div className="card loginForm shadow-lg">
              <div className="card-body">
                <div className="text-center">
                  <img
                    src={logo}
                    alt="Logo"
                    width="150"
                    height="60"
                    className="ml-5 mt-3 mb-5"
                  />
                </div>
                <div className="text-center">
                  <p className="display-5">Admin Login</p>
                </div>
                <form onSubmit={handleSubmit} className="mb-5">
                  <div className="form-group mb-4 form-floating">
                    <input
                      type="email"
                      className="form-control rounded-pilli"
                      id="email"
                      placeholder="Email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    <label htmlFor="floatingInput">Email address</label>
                    <div
                      style={{
                        height:
                          touchedFields.email && errors.email ? "5px" : "0",
                      }}
                    ></div>
                    {touchedFields.email && errors.email && (
                      <div className="error">{errors.email}</div>
                    )}
                  </div>

                  <div className="form-group mb-5 form-floating">
                    <input
                      type="password"
                      className="form-control  rounded-pilli"
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <label htmlFor="floatingInput">Password</label>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn button rounded-pilli mb-3 col-8"
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLoginPage;
