import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { useCart } from "../../context/cart";
import {} from "react-router-dom";

import "../../css/CategoryPage.css";
import axios from "axios";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import DropIn from "braintree-web-drop-in-react";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";

const Checkout = () => {
  const [clientToken, setClientToken] = useState("");
  const [instance, setInstance] = useState("");
  const [auth] = useAuth();
  const { cart, clearCart, getCartTotal, decrementItem, getCartTT } = useCart();
  const [showModal, setShowModal] = useState(false);
  const totalAmount = getCartTT();

  const navigate = useNavigate();
  const location = useLocation();
  const userId = auth.currentUser.id;
  const isLoggedIn = !!auth.token;
  const apiUrl = `${process.env.REACT_APP_API}`;

  const ship = getCartTT() > 0 ? 7 : 0;

  const handleBuy = async (req, res) => {
    try {
      const insufficientItems = [];
      for (let i = 0; i < cart.length; i++) {
        const response = await fetch(`${apiUrl}/product/${cart[i].product}`);
        const data = await response.json();

        const chosenSize = data.productSize.sizes.find(
          (sizeObj) => sizeObj.label === cart[i].selectedSize
        );

        const presentQty = chosenSize.quantity;

        if (presentQty < cart[i].quantity) {
          insufficientItems.push(data.productName);
          decrementItem(data, cart[i].selectedSize);
        }
      }

      if (insufficientItems.length > 0) {
        // Alert user of out-of-stock items and do not proceed with transaction
        const message = ` The following items are out of stock: ${insufficientItems.join(
          ", "
        )}. \n We have removed these items from your cart.`;
        toast.error(message, { duration: 10000 });
        navigate("/cart");

        return;
      }
      
      console.log(totalAmount);
      await axios
        .post("/stripe-checkout", {
          userId,
          totalAmount,
          ship,
          cart,
        })
        .then((res) => res.data)
        .then((url) => {
          window.location.href = url;
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  const count = getCartTotal();
  return (
    <>
      {isLoggedIn ? (
        <>
          {count > 0 ? (
            <>
              <Header></Header>
              <div className="container mb-5">
                <h1
                  className="mt-4 text-center"
                  style={{ color: "#002E6A", fontWeight: "400" }}
                >
                  Confirm Address
                </h1>
                <hr />
                <div className="container d-flex justify-content-center  ">
                  <div className="col-md-6 d-flex justify-content-center">
                    <div className="mt-5 mb-2">
                      <h2
                        style={{ color: "#C70044", fontWeight: "300" }}
                        className="ml-3"
                      >
                        Delivery Address:
                      </h2>
                      <h5>
                        {auth.currentUser.address.street1},{" "}
                        {auth.currentUser.address.street2}
                      </h5>
                      <h5>
                        {auth.currentUser.address.city},
                        {auth.currentUser.address.state},
                        {auth.currentUser.address.zip}
                      </h5>
                      <div className="d-flex justify-content-center mt-4 mb-3">
                        <button
                          className="btn btn-link"
                          style={{ color: "blue" }}
                          onClick={() =>
                            navigate("/profile", {
                              state: "/cart/checkout",
                            })
                          }
                        >
                          Change Address
                        </button>
                      </div>

                      <div className="d-flex justify-content-center mt-4 mb-3">
                        <button
                          onClick={handleBuy}
                          className="btn btn-success w-100 mt-8"
                          style={{ color: "white" }}
                        >
                          Proceed To Payment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer></Footer>
            </>
          ) : (
            <>{navigate(location.state || "/", { replace: true })}</>
          )}
        </>
      ) : (
        <div className="continer mt-5">
          <div className="d-flex justify-content-center">
            <p>You need to be logged in to view contents</p>
            <br />
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn add-to-cart"
              onClick={() => navigate("/login")}
            >
              Click to login
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Checkout;
