import React, {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from "../../components/images/logo-transparent-png.png";
import {toast} from "react-hot-toast";

function ResetPassword() {
    const { id, token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const apiUrl = `${process.env.REACT_APP_API}`;
    const navigate = useNavigate();

    useEffect(() => {
        axios
            .get(`${apiUrl}/resetpassword/${id}/${token}`)
            .then((response) => {
            })
            .catch((error) => {
                console.error(error);
                toast.error("Error")
            });
    }, []);

    function validatePassword() {
        if (password.length < 8) {
            setErrorMessage('Password must be at least 8 characters long');
            return false;
        }
        if (!/[A-Z]/.test(password)) {
            setErrorMessage('Password must contain at least one uppercase letter');
            return false;
        }
        if (!/[\W_]/.test(password)) {
            setErrorMessage('Password must contain at least one special character');
            return false;
        }
        return true;
    }


    async function handleSubmit(event) {
        event.preventDefault();

        const update = {
            password, id
        }
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match!');
            return;
        }

        if (!validatePassword()) {
            return;
        }

        try {
            const {data} = await axios.put(
                `${apiUrl}/update-password`,
                update
            );
            if (data.error) {
                console.log(data);
                toast.error(data.error);
            } else {
                toast.success(data.message);
                navigate("/login", {replace: true});
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="container mt-4">
            <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-8 p-5 ">
                    <div className="card loginForm shadow-lg">
                        <div className="card-body">
                            <div className="text-center">
                                <img
                                    src={logo}
                                    alt="Logo"
                                    width="150"
                                    height="60"
                                    className="ml-5 mt-3 mb-5"
                                />
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="container">
                                    <div className="col">
                                        <div className="col d-flex justify-content-center mt-2">
                                            <label>
                                                New password:
                                                <input type="password"
                                                       className="form-control"
                                                       value={password}
                                                       onChange={(event) => setPassword(event.target.value)}/>
                                            </label>
                                        </div>

                                        <div className="col d-flex justify-content-center mt-2">
                                            <label>
                                                Confirm password:
                                                <input type="password"
                                                       className="form-control"
                                                       value={confirmPassword}
                                                       onChange={(event) => setConfirmPassword(event.target.value)}/>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col d-flex justify-content-center mt-3 mb-5">
                                        <button type="submit" className="btn button">Reset password</button>
                                    </div>
                                    <div className="col d-flex justify-content-center">
                                        {errorMessage && <p>{errorMessage}</p>}
                                    </div>
                                </div>

                                    </form>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;

