import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/header/AdminHeader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const SubCategoryAddPage = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [nameVisited, setNameVisited] = useState(false);
  const [image, setImage] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryVisited, setCategoryVisited] = useState(false);

  const onFileChange = (e) => {
    console.log(e.target.files);
    setImage(e.target.files[0]);
  };
  const apiUrl = `${process.env.REACT_APP_API}`;
  const getData = async (e) => {
    try {
      const res = await fetch(`${apiUrl}/categories`, {
        method: "GET",
      });
      const data = await res.json();
      setCategories(data);
     
    } catch (err) {
      console.log("error");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  async function addSubcategory(e) {
    e.preventDefault();

    let formData = new FormData();
    formData.append("name", name);
    formData.append("category", selectedCategory);
    if (image) {
      formData.append("image", image);
    }

    const res = await fetch(`${apiUrl}/subcategory`, {
      method: "POST",
      body: formData,
    });
    const data = await res.json();
    console.log(data);

    if (res.status === 422 || !data) {
      toast.error("Error adding subcategory.");
      console.log("error");
    } else {
      toast.success("Added subcategory successfully!");
      console.log("data added");
      navigate("/admin/subcategory/view");
    }
  }

  return (
    <>
      <AdminHeader />
      <div className="container pt-5 col-10">
        <div className="mt-5 pt-3">
          <div className="card shadow-lg p-3">
            <h1 className="text-center"> Add Subcategory</h1>
            <form onSubmit={addSubcategory}>
              <div className="mb-3">
                <label htmlFor="productName" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="productName"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  required
                  onBlur={() => {
                    setNameVisited(true);
                  }}
                />
                {nameVisited && name === "" && <div className="text-danger">Name is required</div>}
              </div>
              <div className="mb-3">
                <label htmlFor="categoryGender" className="form-label">
                  Category
                </label>
                <select
                  className="form-select"
                  id="categoryGender"
                  value={selectedCategory}
                  onChange={(event) => setSelectedCategory(event.target.value)}
                  required
                  onBlur={() => {
                    setCategoryVisited(true);
                  }}
                >
                  <option value="">Select a category</option>
                  {Object.values(categories).map((data) => (
                    <option key={data.id} value={data._id}>
                      {data.name}
                    </option>
                  ))}
                </select>
                {categoryVisited && selectedCategory === "" && <div className="text-danger">Category is required</div>}
              </div>
              <div className="mb-3">
                <label htmlFor="front-image" className="form-label">
                  Image
                </label>
                <input
                  type="file"
                  className="form-control-file"
                  id="frontImage"
                  accept="image/*"
                  onChange={onFileChange}
                />
              </div>
              <button type="submit" className="btn button mt-3">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubCategoryAddPage;
