import React from "react";

const NotFoundPage = () => {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-md-6 offset-md-3 text-center">
          <h1 className="display-4">404 Not Found</h1>
          <p className="lead">The page you are looking for does not exist.</p>
          <p>Here are some helpful links:</p>
          <ul className="list-unstyled">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
