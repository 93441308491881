import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AdminHeader from "../../components/header/AdminHeader";
import { toast } from "react-hot-toast";
import axios from "axios";
const SubCategoryUpdatePage = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [id, setId] = useState("");

  const params = useParams();

  const onFileChange = (e) => {
    
    
    const file = e.target.files[0];
    console.log(file)
    
    if (file) {
      setImageFile(file);
      console.log(imageFile)
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageFile(file)
        setImageUrl(reader.result);
        console.log(imageFile)
       
      };
    } else {
      setImageFile(null);
      setImageUrl("");
    }
  };

  const setData = (e) => {
    console.log(e.target.value);
    setName(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getCategories();
      await getSubCategory();
    };
    fetchData();
  }, []);

  useEffect(() => {
    getSubCategories();
  }, []);

  const apiUrl = `${process.env.REACT_APP_API}`;
  const getSubCategory = async () => {
    try {
      const res = await fetch(
        `${apiUrl}/subcategory/${params.slug}`,
        {
          method: "GET",
        }
      );
      const data = await res.json();
      setName(data.name);
      setId(data._id);
      setSelectedCategory(data.category);
      // Extract the filename from the image URL
      const filename = data.image;
      setImageUrl(filename);
    } catch (err) {
      console.log(err);
    }
  };

  const getSubCategories = async () => {
    try {
      const res = await fetch(`${apiUrl}/subcategories`, {
        method: "GET",
      });
      await res.json();
      console.log("get data");
    } catch (err) {
      console.log("error");
    }
  };

  const getCategories = async () => {
    try {
      const res = await fetch(`${apiUrl}/categories`, {
        method: "GET",
      });
      const data = await res.json();
      setCategory(data);
      console.log("get data");
    } catch (err) {
      console.log("error");
    }
  };

  const updateSubCategory = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData();
      formData.append("name", name);
      formData.append("category", selectedCategory);
      if (imageFile) {
        formData.append("image", imageFile);
      }

      // const res2 = await fetch(`${apiUrl}/subcategory/${id}`, {
      //   method: "PUT",
      //   headers: {
      //     "Access-Control-Allow-Origin": "http://localhost:3000",
      //     mode: "no-cors",
      //   },
      //   body: formData,
      // });

      const res2 = await axios.put(
        `${apiUrl}/subcategory/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // const data2 = await res2.json();

      console.log(res2.data);
      toast.success("SubCategory Updated Successfully!");
      navigate("/admin/subcategory/view");

      // if (res2.status === 422 || !data2) {
      //   alert("fill the data");
      // } else {
      //   toast.success("Successfully updated subcategory data!");
      //   await getSubCategory();
      //   navigate("/admin/subcategory/view");
      // }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <AdminHeader />
      <div className="container pt-5">
        <div className="mt-5 pt-3">
          <div className="card shadow-lg p-3">
            <h1 className="text-center">Subcategory Update</h1>
            <form onSubmit={updateSubCategory} encType="multipart/form-data">
              <div className="row mb-3">
                <label className="col-sm-4 col-form-label">Name</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    onChange={setData}
                    value={name}
                    name="name"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-4 col-form-label">Current Image</label>
               
                
                <img
                  src={imageUrl}
                  className="img-"
                  alt="imageUrl"
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "contain",
                    objectPosition: "center center",
                    marginBottom: "5px",
                    marginLeft: "10px",
                  }}
                />
             
              </div>
              <div className="row mb-3">
                <label className="col-sm-4 col-form-label">New Image</label>
                <div className="col-sm-8">
                  {!imageFile && !imageUrl && <p>No image chosen</p>}
                  <input type="file" id="image" onChange={onFileChange} />
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="categoryGender"
                  className="col-sm-4 col-form-label"
                >
                  Category
                </label>
                <div className="col-sm-8">
                  <select
                    className="form-select"
                    id="categoryGender"
                    value={selectedCategory}
                    onChange={(event) =>
                      setSelectedCategory(event.target.value)
                    }
                  >
                    <option value="">Select a category</option>
                    {Object.values(category).map((data) => (
                      <option key={data.id} value={data._id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mb-3">
                <label
                  htmlFor="submitButton"
                  className="col-sm-4 col-form-label"
                ></label>
                <div className="col-sm-8">
                  <button type="submit" className="btn button mt-3">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubCategoryUpdatePage;
