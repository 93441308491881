import React, { useState } from "react";
import axios from "axios";
import "../../css/ProductAddPage.css";

import AdminHeader from "../../components/header/AdminHeader";
import { toast } from "react-hot-toast";
const sgMail = require("@sendgrid/mail");
const dotenv = require("dotenv");
dotenv.config();

sgMail.setApiKey(process.env.SENDGRID_KEY);
const apiUrl = `${process.env.REACT_APP_API}`;
function SendEmail() {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = async () => {
    toast.success("Email Sent");
    try {
      
      await axios.post(`${apiUrl}/send`, {
        subject: subject,
        message: message,
      });
     
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <AdminHeader />
      <div className="container pt-5 ">
        <div className="mt-5 pt-3">
          <div className="card shadow-lg p-3">
            <h1 className="text-center">Send Email</h1>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="row mb-3">
                <label
                  htmlFor="productName"
                  className="col-sm-4 col-form-label"
                >
                  Subject
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="productName"
                    value={subject}
                    onChange={(event) => setSubject(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="productDescription"
                  className="col-sm-4 col-form-label"
                >
                  Message
                </label>
                <div className="col-sm-8">
                  <textarea
                    className="form-control"
                    id="productDescription"
                    rows="5"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="submitButton"
                  className="col-sm-4 col-form-label"
                ></label>
                <div className="col-sm-8">
                  <button type="submit" className="btn button mt-3">
                    SEND
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default SendEmail;
