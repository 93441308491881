import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingGIF from "../images/loading.webp";

export default function Loading() {
  const [count, setCount] = useState(3);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => --currentCount);
    }, 50);

    if (location.pathname.startsWith("/admin")) {
      count === 0 &&
        navigate("/admin", {
          state: location.pathname,
        });
    } else {
      count === 0 &&
        navigate("/login", {
          state: location.pathname,
        });
    }

    return () => clearInterval(interval);
  }, [count, navigate, location.pathname]);

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <img src={LoadingGIF} alt="Loading" />
      <br />
      <h1>Login Required.</h1>
    </div>
  );
}
