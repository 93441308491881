import "./App.css";
import React, { useEffect } from "react";
import HomePage from "./pages/client/HomePage";
import LoginPage from "./pages/client/LoginPage";
import { Route, Routes } from "react-router-dom";
import SignupPage from "./pages/client/SignupPage";
import WishlistPage from "./pages/client/WishlistPage";
import CartPage from "./pages/client/CartPage";
import AdminLoginPage from "./pages/admin/AdminLoginPage";
import ProductAddPage from "./pages/admin/ProductAddPage";
import SubCategoryAddPage from "./pages/admin/SubCategoryAddPage";
import SubCategoryViewPage from "./pages/admin/SubCategoryViewPage";
import SubCategoryUpdatePage from "./pages/admin/SubCategoryUpdatePage";
import ProductDetailPage from "./pages/client/ProductDetailPage";
import PrivateRoute from "./components/routes/PrivateRoute";
import AdminRoute from "./components/routes/AdminRoute";
import ProductViewPage from "./pages/admin/ProductViewPage";
import ProductUpdatePage from "./pages/admin/ProductUpdatePage";
import CategoryPage from "./pages/client/CategoryPage";
import ProductPage from "./pages/client/ProductPage";
import AdminDashboardPage from "./pages/admin/AdminDashboardPage";
import Search from "./pages/client/SearchProduct";
import UserOrders from "./pages/client/UserOrders";
import Checkout from "./pages/client/Checkout";
import UserProfilePage from "./pages/client/UserProfilePage";
import { Toaster } from "react-hot-toast";
import NotFoundPage from "./pages/client/NotFoundPage";
import AdminOrders from "./pages/admin/Orders";
import PastOrders from "./pages/admin/PastOrders";
import CarouselImagesAddPage from "./pages/admin/CarouselImagesAddPage";
import ReviewForm from "./pages/client/ReviewForm";
import BulkEmail from "./pages/admin/BulkEmail";
import ResetPassword from "./pages/client/ResetPassword";
import ForgotPasswordPage from "./pages/client/ForgotPasswordPage";
import Policy from "./pages/client/Terms";
import Analytics from "./pages/admin/Analytics";
import CheckoutSuccess from "./pages/client/success";
import AboutUs from "./pages/client/About";
import Reviews from "./pages/client/Reviews";


function App() {
  useEffect(() => {
    var hours = 1; // to clear the localStorage after 1 hour
    var now = new Date().getTime();
    var setupTime = localStorage.getItem("setupTime");

    if (setupTime == null) {
      localStorage.setItem("setupTime", now);
    } else {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.clear();
        localStorage.setItem("setupTime", now);
      }
    }
  }, []);

  return (
    <>
      <Toaster />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/terms&conditions" element={<Policy />} />
        <Route path="/search" element={<Search />} />
        <Route path="/admin" element={<AdminLoginPage />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/resetpassword/:id/:token" element={<ResetPassword />} />
        <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="/category/:category" element={<CategoryPage />} />
        <Route index element={<HomePage />} />
        <Route
          path="/:category/products/:subcategory"
          element={<ProductPage />}
        />
        <Route path="/products/:id" element={<ProductDetailPage />} />
        <Route path="/orders" element={<UserOrders />} />
        <Route path="/profile" element={<UserProfilePage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/wishlist" element={<WishlistPage />} />
        <Route path="/cart/checkout" element={<Checkout />} />
        <Route path="/reviews" element={<ReviewForm />} />
        <Route path="/checkout-success" element={<CheckoutSuccess />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/all-reviews" element={<Reviews />} />

        {/*Admin Routes*/}
        <Route path="/admin" element={<AdminRoute />}>
          <Route path="add" element={<ProductAddPage />} />
          <Route path="subcategory/add" element={<SubCategoryAddPage />} />
          <Route path="subcategory/view" element={<SubCategoryViewPage />} />
          <Route
            path="subcategory/update/:slug"
            element={<SubCategoryUpdatePage />}
          />
          <Route path="orders" element={<AdminOrders />}></Route>
          <Route path="past-orders" element={<PastOrders />}></Route>
          <Route path="send-email" element={<BulkEmail />}></Route>
          <Route path="analytics" element={<Analytics />}></Route>
          <Route path="product/view" element={<ProductViewPage />}></Route>
          <Route
            path="product/update/:productId"
            element={<ProductUpdatePage />}
          />
          <Route
            path="carousel-images"
            element={<CarouselImagesAddPage />}
          ></Route>
          <Route path="dashboard" element={<AdminDashboardPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
