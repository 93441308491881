import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import "../../css/HomePage.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { toast } from "react-hot-toast";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";

const s3 = new AWS.S3({
  credentials: {
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_S3_BUCKET_REGION,
  },
});

//react-app

const HomePage = () => {
  const [reviews, setReviews] = useState([]);
  const [currentReview, setCurrentReview] = useState(0);
  const [featured, setFeatured] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [images, setImages] = useState([]);

  const [auth] = useAuth();

  const apiUrl = `${process.env.REACT_APP_API}`;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch(`${apiUrl}/reviews`);
        const data = await response.json();
        setReviews(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchReviews();
  }, []);

  const listImages = async () => {
    const params = {
      Bucket: "carousel-images-upload-1",
    };

    try {
      const response = await s3.listObjects(params).promise();
      setImages(
        response.Contents.map(
          (item) =>
            `https://carousel-images-upload-1.s3.amazonaws.com/${item.Key}`
        )
      );
    } catch (error) {
      console.log("Error listing images:", error);
    }
  };

  useEffect(() => {
    listImages();
  }, []);

  const imagesFromS3 = images.map((image) => ({
    src: image,
    alt: image.split("/").pop(),
  }));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentReview((prev) => (prev + 1) % reviews.length);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [reviews]);

  const filterProductByQuantity = (products) => {
    return products.filter((p) => {
      return p.productSize.sizes.some((size) => size.quantity > 0);
    });
  };
  useEffect(() => {
    const loadFeatured = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/featured-product`);
        setFeatured(filterProductByQuantity(data));
      } catch (err) {
        console.log(err);
      }
    };

    loadFeatured();
  }, []);

  const fetchWishlist = async () => {
    try {
      const userId = auth.currentUser.id;
      if (userId) {
        const response = await axios.get(`${apiUrl}/wishlist`);
        setWishlist(response.data.products);
      }
    } catch (error) {
      console.log(error);
    }
  };
 
  useEffect(() => {
    
    if (auth.currentUser && auth.currentUser.id) {
      fetchWishlist();
    }
  }, [auth.currentUser]);

  

  const isInWishlist = (productId) => {
    return wishlist.some((item) => item._id === productId);
  };

  const addToWishlist = async (productId) => {
    try {
      fetchWishlist();
      const response = await axios.post(`${apiUrl}/wishlist/add`, {
        productId,
      });
      setWishlist([...wishlist, response.data.products]);
      toast.success("Added to Wishlist");
      await fetchWishlist();
    } catch (error) {
      console.log(error);
    }
  };
  const removeFromWishlist = async (productId) => {
    try {
      if (!auth) {
        return;
      }
      await axios.delete(`${apiUrl}/wishlist/delete/${productId}`);
      const updatedWishlist = wishlist.filter(
        (products) => products._id !== productId
      );
      setWishlist(updatedWishlist);
      toast.success("Removed from Wishlist");
      await fetchWishlist();
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <>
      <div className="header-fixed">
        <Header />
      </div>
      <ImageCarousel images={imagesFromS3} />
      <div className="container mt-5">
        <div className="flex text-center mt-5 mb-2">
          <h1 className="font-weight-bold">NEW ADDITION</h1>
          <hr className="lines" />
        </div>
      </div>
      <div className="container mt-5 box">
        <div className="product-list row  row-cols-1 row-cols-md-4 g-4">
          {featured.length > 0 &&
            featured.map((product) => (
              <div className="product col-6 col-md-3" key={product.id}>
                <div className="card h-100">
                  <div className="position-relative">
                    <Link
                      to={`/products/${product._id}`}
                      className="text-decoration-none"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "instant" });
                      }}
                    >
                      <img
                        src={product.images.front}
                        alt={product.name}
                        className="card-img-top img-fluid image-container"
                        // style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                        style={{
                          Width: "100%",
                          objectFit: "cover",
                          objectPosition: "center center",
                        }}
                        loading="lazy"
                      />
                      {product.salePrice > 0 && (
                        <span className="off">
                          {Math.round(
                            ((product.productPrice - product.salePrice) /
                              product.productPrice) *
                              100
                          )}
                          % off
                        </span>
                      )}
                    </Link>
                    <button
                      className={`position-absolute bottom-0 end-0 btn heart ${
                        isInWishlist(product._id)
                          ? "heart-buttonSelected"
                          : "heart-buttonUnselected"
                      }`}
                      onClick={() => {
                        if (!localStorage.getItem("auth")) {
                          toast.error("You're not logged in");
                          return;
                        }
                        if (isInWishlist(product._id)) {
                          removeFromWishlist(product._id);
                        } else {
                          addToWishlist(product._id);
                        }
                      }}
                    >
                      <HeartFilled
                        style={{
                          fontSize: "1.4rem",
                          borderRadius: "50px",
                          backgroundColor: "#002e6a",
                          padding: "7px",
                        }}
                      />
                    </button>
                  </div>

                  <div className="card-body d-flex flex-column align-items-start">
                    <Link
                      to={`/products/${product._id}`}
                      className="text-decoration-none"
                    >
                      <h4 className="card-title card-title-design">
                        {product.productName}
                      </h4>
                    </Link>
                    <div className="divider" />
                    <div className="d-flex justify-content-between align-items-center w-100 pt-3">
                      <div>
                        {product.salePrice > 0 ? (
                          <p className="card-text priceTag flex-grow-0">
                            <span className="salePrice">
                              ${product.salePrice}
                            </span>
                            <span className="oldPrice">
                              ${product.productPrice}
                            </span>
                          </p>
                        ) : (
                          <p className="card-text priceTag flex-grow-0">
                            ${product.productPrice}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="container mb-5">
        <div className="flex text-center mt-5">
          <h1 style={{ fontWeight: "500" }}>Reviews</h1>
          <p style={{ fontWeight: "400" }}>
            Everything to love about Kanchan's Choice
          </p>
          <hr />
        </div>
        <div className="flex text-center mt-3 reviews p-3">
          {reviews.length ? (
            <>
              <div className="star-rating">
                {Array(reviews[currentReview].rating)
                  .fill()
                  .map((_, index) => (
                    <i key={index} className="fas fa-star selected"></i>
                  ))}
              </div>
              <a href="/all-reviews" style={{ textDecoration: "none" }}>
                <p className="rev">
                  "
                  {reviews[currentReview].comment.length > 50
                    ? `${reviews[currentReview].comment.slice(0, 60)}...`
                    : reviews[currentReview].comment}
                  "
                </p>
              </a>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default HomePage;
