import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="d-flex foot flex-wrap justify-content-center align-items-center py-1 my-1 border-top flex-column ">
      <p style={{ fontWeight: "350" }}>Connect with us on our Social Media</p>
      <ul className="nav justify-content-center">
        <li className="nav-item">
          <a
            href="https://www.facebook.com/kanchanschoice/"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link px-2"
          >
            <i className="fa-brands fa-facebook fa-2x"></i>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="https://www.instagram.com/kanchanschoice/"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link px-2"
          >
            <i className="fa-brands fa-instagram fa-2x"></i>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="https://www.tiktok.com/@kanchanschoice"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link px-2"
          >
            <i className="fa-brands fa-tiktok fa-2x"></i>
          </a>
        </li>
      </ul>
      <p style={{ fontWeight: "350" }}>
        © 2023 Team Everest. All Rights Reserved
      </p>
    </footer>
  );
};

export default Footer;
