import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
const AboutUs = () => {
  return (
    <div>
      <Header></Header>
      <div className="container d-flex justify-content-center mt-5">
        <div>
          <div className="d-flex justify-content-center mb-3">
            <h1> About Us </h1>
          </div>
          <div className="container d-flex justify-content-center">
            <img
              src="https://subcategories-image-upload-aws-1.s3.amazonaws.com/headshot.jpeg"
              alt="pp"
              style={{
                borderRadius: "50%",
                width: "200px",
                height: "200px",
                objectFit: "cover",
                objectPosition: "center top",
              }}
            ></img>
          </div>
          <div className="container d-flex justify-content-center mt-4">
            <span style={{ textAlign: "justify" }}>
              {" "}
              <p style={{ fontWeight: "400" }}>
                {" "}
                Namaste ! My name is Kanchan Thakur. As a mother of two young
                children, I always had the dream of starting my own business.
                However, finding the time to pursue this dream was a challenge.{" "}
              </p>{" "}
              <p style={{ fontWeight: "400" }}>
                {" "}
                One day, while shopping for traditional clothing in the USA, I
                was shocked by the high prices and realized that middle-class
                individuals like myself could not afford such items. This
                experience ignited a passion within me to provide affordable,
                high-quality traditional clothing to the community.{" "}
              </p>
              <p style={{ fontWeight: "400" }}>
                I started my small business from home, using Facebook
                Marketplace as a platform. Thanks to reasonable pricing, people
                began to recognize and purchase my products. We sell both casual
                and traditional wear, aiming to provide our customers with all
                products at one place and at affordable prices. My motive behind
                Kanchan's Choice is to offer good-quality traditional clothing
                solutions at affordable prices for everyone. We have plans to
                add all cultural clothing options at one place, and we are
                committed to making it accessible for all. Thank you for
                choosing us!
              </p>
            </span>
          </div>
          <hr />
          <div className="mb-3">
            <h3 style={{ fontWeight: "600" }}>Contact Us:</h3>
          </div>
          <div>
            <p style={{ fontWeight: "400" }}>
              <strong>Phone</strong>: 571-699-6505{" "}
              <i>(For inquiries about products)</i>
            </p>
            <p style={{ fontWeight: "400" }}>
              <strong>Email</strong>: support@kanchanschoice.com
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default AboutUs;
