import React, { useState, useEffect } from "react";
import "./ImageCarousel.css";

function ImageCarousel(props) {
  const { images } = props;

  // Add a state to keep track of the active image index
  const [activeIndex, setActiveIndex] = useState(0);

  // Function to handle click on previous button
  const handlePrevClick = () => {
    setActiveIndex((activeIndex - 1 + images.length) % images.length);
  };

  // Function to handle click on next button
  const handleNextClick = () => {
    setActiveIndex((activeIndex + 1) % images.length);
  };

  // Use useEffect to update activeIndex every 1 second
  useEffect(() => {
    const timer = setTimeout(() => {
      setActiveIndex((activeIndex + 1) % images.length);
    }, 3000);
    return () => clearTimeout(timer);
  }, [activeIndex, images.length]);

  return (
    <div className="image-carousel mt-5 rounded">
      {images.map((image, index) => (
        <div
          key={index}
          className={
            index === activeIndex ? "carousel-item active" : "carousel-item"
          }
        >
          <img
            src={image.src}
            alt={image.alt}
            className="d-block main-image w-100"
          />
        </div>
      ))}

      {/* Add buttons to switch images */}
      <button className="carousel-button prev" onClick={handlePrevClick}>
        &#10094;
      </button>
      <button className="carousel-button next" onClick={handleNextClick}>
        &#10095;
      </button>
    </div>
  );
}

export default ImageCarousel;
