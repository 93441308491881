import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../components/header/Header";
import { useAuth } from "../../context/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import { toast } from "react-hot-toast";
const WishlistPage = () => {
  const [wishlist, setWishlist] = useState([]);
  const [productList, setProductList] = useState([]);
  const [auth] = useAuth();
  const isLoggedIn = !!auth.token;
  const local = JSON.parse(localStorage.getItem("auth"));
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    getWishlist();
  }, []);

  useEffect(() => {
    getProductList();
  }, [wishlist]);
  const apiUrl = `${process.env.REACT_APP_API}`;

  const getWishlist = async () => {
    try {
      const token = local.token;
      const res = await axios.get(`${apiUrl}/wishlist`, {
        headers: {
          Authorization: token,
        },
      });
      setWishlist(res.data.products);
      await getProductList(); // call getProductList after setting wishlist state
    } catch (err) {
      console.error(err.message);
    }
  };

  const getProductList = async () => {
    try {
      const promises = wishlist.map(async (product) => {
        const res = await axios.get(`${apiUrl}/product/${product._id}`);
        return res.data;
      });
      const products = await Promise.all(promises);
      setProductList(products);
    } catch (err) {
      console.error(err.message);
    }
  };

  const removeProductFromWishlist = async (productId) => {
    try {
      const auth = JSON.parse(localStorage.getItem("auth"));
      if (!auth) {
        return;
      }
      const userId = auth.currentUser.id;
      await axios.delete(`${apiUrl}/wishlist/delete/${productId}`);
      const updatedWishlist = wishlist.filter(
        (products) => products._id !== productId
      );
      setWishlist(updatedWishlist);
      toast.success("Removed from Wishlist");
      getProductList();
    } catch (err) {
      console.error(err.message);
      toast.error(err.message);
    }
  };

  return (
    <>
      <Header></Header>
      <div className="container mt-5">
        {isLoggedIn ? (
          <>
            <div className="d-flex flex-column ">
              <div className="d-flex justify-content-center mb-5">
                <h1 style={{ marginTop: "10px", fontWeight: "450" }}>
                  My Wishlist
                </h1>
              </div>
            </div>
            <hr />
            <div className="table-container d-flex flex-column">
              {wishlist.length > 0 ? (
                <>
                  <div className="d-flex justify-content-center">
                    <table className="table cart-table">
                      <tbody>
                        {productList.map((item, index) => (
                          <tr key={item.productName}>
                            <td>
                              <Link to={`/products/${item._id}`}>
                                <img
                                  src={item.images.front}
                                  alt={item.productName}
                                  className="cart-image img-fluid"
                                  style={{
                                    Width: "100%",
                                    objectFit: "cover",
                                    objectPosition: "center center",
                                  }}
                                  loading="lazy"
                                />
                              </Link>
                            </td>

                            <td>
                              <h4>{item.productName}</h4>
                            </td>
                        
                            <td>
                              <h4>${item.salePrice > 0? item.salePrice:item.productPrice}</h4>
                            </td>
                            <td>
                              <button
                                className="btn"
                                onClick={() =>
                                  removeProductFromWishlist(item._id)
                                }
                              >
                                <i className="fa-solid fa-trash "></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "70vh" }}
                >
                  <div className="col">
                    <div className="d-flex justify-content-center">
                      <h1 className="p-5">Wishlist is Empty</h1>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn add-to-cart"
                        onClick={() =>
                          navigate(location.state || "/", { replace: true })
                        }
                      >
                        Explore Options
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="continer mt-5">
            <div className="d-flex justify-content-center">
              <p>You need to be logged in to view contents</p>
              <br />
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn add-to-cart"
                onClick={() =>
                  navigate("/login", {
                    state: { from: location.pathname },
                  })
                }
              >
                Click to login
              </button>
            </div>
          </div>
        )}
      </div>
      <Footer></Footer>
    </>
  );
};

export default WishlistPage;
