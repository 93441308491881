import React  from "react";
import { useAuth} from "../../context/auth";
import { Link , useNavigate} from "react-router-dom";
import { toast } from "react-hot-toast"
import logo from "../../components/images/logo-transparent-png.png";

const AdminDashboardPage = () => {
  
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();

  const logout = () => {
    setAuth({ ...auth, user: null, token: "" });
    localStorage.clear();
    navigate("/");
    toast.success("Successful Logout");
  };

  return (
    <>
    <div className="text-center">
                  <img
                    src={logo}
                    alt="Logo"
                    width="250"
                    height="100"
                    className="ml-5  mb-2"
                    style={{marginTop:"-35px"}}
                  />
                </div>
    
      <div className="container p-5">
        <div className="container-fluid mb-5">
          <h1>Hi Kanchan,</h1>
          <span style={{marginLeft:"75%"}}><button onClick={logout} className="btn btn-info col-4">Logout</button></span>
        </div>
        <div className="container-fluid">
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
            <div className="col">
              <Link to="/admin/orders" className="text-decoration-none">
                <div className="card bg-primary text-white h-100 d-flex justify-content-center align-items-center">
                  <h3 className="card-title text-center">New Orders</h3>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link to="/admin/past-orders" className="text-decoration-none">
                <div className="card bg-primary text-white h-100 d-flex justify-content-center align-items-center">
                  <h3 className="card-title text-center">Past Orders</h3>
                </div>
              </Link>
            </div>
            <div className="col">
            <Link to="/admin/analytics" className="text-decoration-none">
              <div className="card bg-secondary text-white h-100 d-flex justify-content-center align-items-center">
                 <h3 className="card-title text-center">Analytics</h3>
              </div>
            </Link>
            </div>
            <div className="col">
              <Link to="/admin/add" className="text-decoration-none">
                <div className="card bg-warning text-white h-100 d-flex justify-content-center align-items-center">
                  <h3 className="card-title text-center">Add Product</h3>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link to="/admin/product/view" className="text-decoration-none">
                <div className="card bg-info text-white h-100 d-flex justify-content-center align-items-center">
                  <h3 className="card-title text-center">
                    View/Update Product
                  </h3>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link to="/admin/subcategory/add" className="text-decoration-none">
                <div className="card bg-danger text-white h-100 d-flex justify-content-center align-items-center">
                  <h3 className="card-title text-center">Add Subcategory</h3>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link
                to="/admin/subcategory/view"
                className="text-decoration-none"
              >
                <div className="card bg-dark text-white h-100 d-flex justify-content-center align-items-center">
                  <h3 className="card-title text-center">
                    View/Update Category
                  </h3>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link
                to="/admin/carousel-images"
                className="text-decoration-none"
              >
                <div className="card bg-warning text-white h-100 d-flex justify-content-center align-items-center">
                  <h3 className="card-title text-center">Carousel Images</h3>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link to="/admin/send-email" className="text-decoration-none">
                <div className="card bg-danger text-white h-100 d-flex justify-content-center align-items-center">
                  <h3 className="card-title text-center">Send Email</h3>
                </div>
              </Link>
            </div>
            
                
            
         
            
          </div>
       
        </div>
      </div>
    </>
  );
};

export default AdminDashboardPage;
