import React, { useState } from 'react';
import axios from 'axios';
import logo from "../../components/images/logo-transparent-png.png";
import {toast} from "react-hot-toast";
import { useNavigate} from "react-router-dom";

function ForgotPasswordPage() {
    const [email, setEmail] = useState('');
    const apiUrl = process.env.REACT_APP_API;
    const navigate = useNavigate();

    async function handleSubmit(event) {
        event.preventDefault();

        try {
            await axios.post(`${apiUrl}/sendpasswordlink`, { email });
            toast.success("Reset link Sent.")
            navigate("/");
        } catch (err) {
            console.error(err);
            toast.error('Email not Registered.');
        }
    }

    return (
        <div className="container mt-4">
            <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-8 p-5 ">
                    <div className="card loginForm shadow-lg">
                        <div className="card-body">
                            <div className="text-center">
                                <img
                                    src={logo}
                                    alt="Logo"
                                    width="150"
                                    height="60"
                                    className="ml-5 mt-3 mb-5"
                                />
                            </div>
                            <h4 className="text-center mb-4">Forgot password</h4>
                            <div className="container">
                                <div className="col">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="email">Email address:</label>
                                            <input type="email" className="form-control mt-2" id="email" value={email} onChange={(event) => setEmail(event.target.value)} />
                                        </div>
                                        <div className="col d-flex justify-content-center">
                                            <button type="submit" className="btn button">Send Reset Link</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPasswordPage;

