import React, { useEffect, useState, useRef } from "react";
import "../../css/CategoryPage.css";
import axios from "axios";
import Footer from "../../components/footer/Footer";
import { useAuth } from "../../context/auth";
import AdminHeader from "../../components/header/AdminHeader";

const PastOrders = () => {
  const [auth] = useAuth();
  const [orders, setOrders] = useState([]);
  
  const topRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Set the number of items to display per page

  const apiUrl = `${process.env.REACT_APP_API}`;
  useEffect(() => {
    if (auth.token) {
      getOrders();
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [auth.token, currentPage]);

  const getOrders = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/all-orders`);
      setOrders(data.reverse());
    } catch (err) {
      console.log(err);
    }
  };

  // Calculate the indexes for the current page
  const indexOfLastOrder = currentPage * itemsPerPage;
  const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder).reverse();

  // Calculate the total number of pages
  const totalPages = Math.ceil(orders.length / itemsPerPage);
  // Create an array of page numbers from 1 to totalPages
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  
  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({top: 0, behavior: 'instant'});
  };

  return (
    <>
    <div ref={topRef}></div> 
      <AdminHeader></AdminHeader>
      <div className="container mb-4">
        <h1
          className="mt-5 d-flex justify-content-center"
          style={{
            color: "#002E6A",
            marginTop: "15px",
            fontWeight: "450",
          }}
        >
          {" "}
          Past Orders{" "}
        </h1>
        <div>
          {currentOrders.length === 0 ? (
            <h3 className="mt-5">There are no new orders! </h3>
          ) : (
            currentOrders.reverse().map((order) => 
            (order.status === "Shipped" || order.status === "Cancelled") && (
              // Your order rendering logic here
              <div key={order._id} className="card mb-3">
              <div className="card-body">
                <h5
                  className="card-text"
                  style={{
                    color: order.status === "Shipped" ? "green" : "red",
                  }}
                >
                  Status: {order.status}
                </h5>
                {order.status === "Shipped" ? 
                <p className="card-text">
                  Tracking Number: <a href={`https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=3&text28777=&tLabels=${order.trackingNumber}`}>{order.trackingNumber}</a>
                </p> 
              : null}

                <p className="card-text"  style={{ fontWeight: "350" }}>
                  <strong>Total</strong>: ${order.total}
                </p>
                <p className="card-text" style={{ fontWeight: "350" }}>
                  <strong>Date</strong>: {order.createdAt.substring(0, 10)}
                </p>
                <p className="card-text" style={{ fontWeight: "350" }}>
                <strong>Buyer</strong>: {order.buyer.name.first} {order.buyer.name.last}
                </p>
                <p className="card-text" style={{ fontWeight: "350" }}>
                <strong>Email</strong>: {order.buyer.email}
                </p>
                <p className="card-text" style={{ fontWeight: "350" }}>
                <strong>Phone</strong>: {order.buyer.phone}
                </p>
                <p className="card-text" style={{ fontWeight: "350" }}>
                <strong>Address</strong>: {order.buyer.address.street1},{" "}
                  {order.buyer.address.street2},{" "}
                  {order.buyer.address.city}, {order.buyer.address.state}-
                  {order.buyer.address.zip}, USA
                </p>

                <h6 className="card-subtitle mb-2 text-muted">
                  Products:
                </h6>
                <h7> {order.products.buyer}</h7>
                <hr style={{ marginLeft: "29px" }} />
                <ol>
                  {order.products.map((p) => (
                    <li key={p._id}>
                      {p.product && (
                        <>
                          <img
                            src={p.product.images.front}
                            alt={p.product.productName}
                            width="150"
                            height="150"
                            style={{
                              objectFit: "contain",
                              marginRight: "10px",
                              display: "block",
                            }}
                            loading="lazy"
                          />

                          <p style={{ fontWeight: "350" }}>
                            {" "}
                            {p.product.productName} | Size:{" "}
                            {p.selectedSize} | Qty: {p.quantity} | Price:{" "}
                            <h10 style={{ fontWeight: "900px" }}>
                              ${p.product.salePrice >0 ? p.product.salePrice : p.product.productPrice}{" "}
                            </h10>
                          </p>
                          <hr />
                        </>
                      )}
                    </li>
                  ))}
                </ol>
              </div>
            </div>
              // ...
            ))
          )}
        </div>

        {/* Pagination controls */}

        <div className="pagination">
          {currentPage > 1 && (
            <button
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Prev
            </button>
          )}
          
          {/* {pageNumbers.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              className={currentPage === pageNumber ? "active" : ""}
            >
              {pageNumber}
            </button>
          ))} */}
          
          <button
              key={currentPage}
              onClick={() => handlePageChange(currentPage)}
              className={currentPage === currentPage ? "active" : ""}
            >
               {currentPage} /
                <span className="pagination-text">   {totalPages}
              </span>
            </button>
          
          {currentPage + 1 <= totalPages && (
            <button
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          )}

        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default PastOrders;
