import React, { useState, createContext, useContext, useEffect } from "react";
import axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    currentUser: {
      id: "",
      name: {
        first: "",
        last: "",
      },
      email: "",
      address: {
        street1: "",
        street2: "",
        city: "",
        state: "",
        zip: "",
      },
      phone: "",
    },
    token: "",
  });
  axios.defaults.baseURL = process.env.REACT_APP_API;
  axios.defaults.headers.common["Authorization"] = auth.token;

  useEffect(() => {
    const data = localStorage.getItem("auth");
    if (data) {
      const parsed = JSON.parse(data);
      setAuth({
        ...auth,
        token: parsed.token,
        currentUser: {
          ...auth.currentUser,
          id: parsed.currentUser.id,
          name: {
            ...auth.currentUser.name,
            first: parsed.currentUser.name.first,
            last: parsed.currentUser.name.last,
          },
          email: parsed.currentUser.email,
          address: {
            ...auth.currentUser.address,
            street1: parsed.currentUser.address.street1,
            street2: parsed.currentUser.address.street2,
            city: parsed.currentUser.address.city,
            state: parsed.currentUser.address.state,
            zip: parsed.currentUser.address.zip,
          },
          phone: parsed.currentUser.phone,
        },
      });
    }
    // setTimeout(() => {
    //   localStorage.removeItem("auth");
    // }, 60 * 60 * 1000); // 60 minutes in milliseconds
  }, [localStorage.getItem("auth")]);

  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  );
};
const useAuth = () => useContext(AuthContext);
export { useAuth, AuthProvider };
