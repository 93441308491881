import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
const CheckoutSuccess = () => {
    

    
    
    useEffect(() => {
        // localStorage.removeItem("cart");
        toast.success("Transaction Successful ! ")
      }, []);
    
  return (
    <div className="container d-flex justify-content-center mt-5">
    <div className="checkout-success  ">
      <div className="d-flex justify-content-center">
        <h4 style={{color:"#c70044"}}>Thank you for your purchase!</h4>
      </div>
      <div  className="d-flex justify-content-center" style={{textAlign:"center"}}>
        <p style={{fontWeight:"350"}}>
          Your order has been processed. We will ship your order within 24 to
          48 hours and will notify you by email when your order is shipped. You can view all your orders in the orders section of your profile.
        </p>
      </div>
      <div className="container d-flex justify-content-center" >
        <p>
          <Link to="https://kanchanschoice.com">Continue Shopping</Link>
        </p>
      </div>
      
    </div>
    </div>
  );
};

export default CheckoutSuccess;

