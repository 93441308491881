import React, { useEffect, useState } from "react";
import "../../css/CategoryPage.css";
import axios from "axios";

import { toast } from "react-hot-toast";
import { useAuth } from "../../context/auth";
import AdminHeader from "../../components/header/AdminHeader";
import { Select } from "antd";

const { Option } = Select;

const AdminOrders = () => {
  const [auth] = useAuth();

  const [orders, setOrders] = useState([]);

  const [status] = useState(["Shipped"]);

  const [changeStatus, setChangeStatus] = useState("");
  const [trackingNumbers, setTrackingNumbers] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [orderid, setOrderid] = useState("");

  function handleTrackingNumberSubmit(event, orderId) {
    toast.success("Tracking Number Added !");
    console.log(trackingNumbers[orderId]);
    event.preventDefault();
    // Send an AJAX request to update the order's tracking number in the database.
  }

  function handleTrackingNumberChange(event, orderId) {
    setTrackingNumbers({
      ...trackingNumbers,
      [orderId]: event.target.value,
    });
  }

  useEffect(() => {
    if (auth.token) getOrders();
  }, [auth.token, changeStatus]);

  const apiUrl = `${process.env.REACT_APP_API}`;
  const getOrders = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/all-orders`);
      setOrders(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = async (orderId, value) => {
    try {
      if (value === "Shipped" && trackingNumbers[orderId] === "") {
        alert("Please enter the tracking number.");

        return;
      } else {
        setChangeStatus(value);
        console.log(trackingNumbers[orderId]);
        await axios.put(`${apiUrl}/order-status/${orderId}`, {
          status: value,
          trackingNumber: trackingNumbers[orderId],
        });

        value === "Shipped"
          ? toast.success("Order Shipped!")
          : toast.success("Order Cancelled!");
        getOrders();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancelOrder = (orderId) => {
    setOrderid(orderId);
    setShowConfirm(true);
  };

  const handleConfirmCancelOrder = () => {
    handleChange(orderid, "Cancelled");
    setShowConfirm(false);
  };

  return (
    <>
      <AdminHeader></AdminHeader>

      <div className="container mb-4 ">
        <h1
          className="mt-4 d-flex justify-content-center"
          style={{
            color: "#002E6A",
            marginTop: "15px",

            fontWeight: "450",
          }}
        >
          {" "}
          New Orders{" "}
        </h1>
        <div>
          {orders.length === 0 ? (
            <div>
              <h4>There are no new orders ! </h4>
            </div>
          ) : (
            orders.map(
              (order) =>
                order.status === "Order Received" && (
                  <div key={order._id} className="card mb-3">
                    <div className="card-body">
                      <h5 className="card-text" style={{ color: "green" }}>
                        <h7>Change Status: </h7>
                        <Select
                          className="col-6"
                          defaultValue={order.status}
                          onChange={(value) => handleChange(order._id, value)}
                        >
                          {status.map((s, i) => (
                            <Option
                              key={i}
                              value={s}
                              disabled={
                                s === "Shipped" &&
                                !trackingNumbers.hasOwnProperty(order._id)
                                  ? true
                                  : false
                              }
                            >
                              {" "}
                              {s}{" "}
                            </Option>
                          ))}
                        </Select>
                      </h5>

                      <p className="card-text">
                        <form
                          onSubmit={(event) =>
                            handleTrackingNumberSubmit(event, order._id)
                          }
                        >
                          <label>
                            Tracking Number:
                            <input
                              type="text"
                              value={trackingNumbers[order._id] || ""}
                              onChange={(event) =>
                                handleTrackingNumberChange(event, order._id)
                              }
                            />
                          </label>
                          <button
                            type="submit"
                            class="btn btn-success"
                            style={{ color: "white", marginLeft: "5px" }}
                          >
                            Add
                          </button>
                        </form>
                      </p>

                      <p className="card-text" style={{ fontWeight: "350" }}>
                        <strong>Total</strong>: ${order.total}
                      </p>
                      <p className="card-text" style={{ fontWeight: "350" }}>
                        <strong>Date</strong>:{" "}
                        {order.createdAt.substring(0, 10)}
                      </p>
                      <p className="card-text" style={{ fontWeight: "350" }}>
                        <strong>Buyer</strong>: {order.buyer.name.first}{" "}
                        {order.buyer.name.last}
                      </p>
                      <p className="card-text" style={{ fontWeight: "350" }}>
                        <strong>Email</strong>: {order.buyer.email}
                      </p>
                      <p className="card-text" style={{ fontWeight: "350" }}>
                        <strong>Phone</strong>: {order.buyer.phone}
                      </p>
                      <p className="card-text" style={{ fontWeight: "350" }}>
                        <strong>Address</strong>: {order.buyer.address.street1},{" "}
                        {order.buyer.address.street2},{" "}
                        {order.buyer.address.city}, {order.buyer.address.state}-
                        {order.buyer.address.zip}, USA
                      </p>

                      <h6 className="card-subtitle mb-2 text-muted">
                        Products:
                      </h6>
                      <h7> {order.products.buyer}</h7>
                      <hr style={{ marginLeft: "29px" }} />
                      <ol>
                        {order.products.map((p) => (
                          <li key={p._id}>
                            {p.product && (
                              <>
                                <img
                                  src={p.product.images.front}
                                  alt={p.product.productName}
                                  width="200"
                                  height="200"
                                  style={{
                                    objectFit: "contain",
                                    marginRight: "10px",
                                    display: "block",
                                  }}
                                />

                                <p style={{ fontWeight: "350" }}>
                                  {" "}
                                  {p.product.productName} | Size:{" "}
                                  {p.selectedSize} | Qty: {p.quantity} | Price:{" "}
                                  <h10 style={{ fontWeight: "900px" }}>
                                    $
                                    {p.product.salePrice > 0
                                      ? p.product.salePrice
                                      : p.product.productPrice}{" "}
                                  </h10>
                                </p>
                                <hr />
                              </>
                            )}
                          </li>
                        ))}
                      </ol>
                    </div>

                    <button
                      onClick={() => handleCancelOrder(order._id)}
                      className="btn btn-danger col-5 mb-2"
                      style={{ color: "white", marginLeft: "5px" }}
                    >
                      Cancel-Order
                    </button>
                    {showConfirm && (
                      <div className="mb-3">
                        <p>Are you sure you want to cancel this order?</p>
                        <button
                          className="btn btn-danger"
                          style={{ marginRight: "4px" }}
                          onClick={handleConfirmCancelOrder}
                        >
                          Yes
                        </button>
                        <button
                          className="btn btn-warning"
                          onClick={() => setShowConfirm(false)}
                        >
                          No
                        </button>
                      </div>
                    )}
                  </div>
                )
            )
          )}
        </div>
      </div>
    </>
  );
};

export default AdminOrders;
