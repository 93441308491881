import React, { useState, useEffect } from "react";
import {Link, NavLink } from "react-router-dom";
import "./AdminHeader.css";

import logo from "../images/logo.png";

const AdminHeader = () => {
  const [activeTab, setActiveTab] = useState("");
 
  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  

  return (
    <header>
      <nav className="navbar navbar-expand-lg bg-white border-bottom">
        <div className="container-fluid">
        
          {isMobile ? <> <Link to={"/admin/dashboard"}><img
              className="logo img-fluid"
              src={logo}
              alt="Logo"
              width="4rem"
              height="2rem"
              
            /></Link></> :null}
       
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{marginLeft:"100px"}}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul
              className="navbar-nav justify-content-center mobile "
              style={{ fontWeight: "350" }}
            >
              <li className="nav-item">
                <NavLink
                  exact
                  to="/admin/dashboard"
                  className={`nav-link ${
                    activeTab === "dashboard" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("dashboard")}
                >
                  Dashboard
                </NavLink>
                {isMobile?<hr className="hrik"/>:null}
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/admin/orders"
                  className={`nav-link ${
                    activeTab === "orders" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("orders")}
                >
                  New Orders
                </NavLink>
                {isMobile?<hr className="hrik"/>:null}
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/admin/past-orders"
                  className={`nav-link ${
                    activeTab === "pastOrder" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("pastOrder")}
                >
                  Past Order
                </NavLink>
                {isMobile?<hr className="hrik"/>:null}
              </li>
              {/*<li className="nav-item">*/}
              {/*  <NavLink*/}
              {/*    to="/admin/analytics"*/}
              {/*    className={`nav-link ${*/}
              {/*      activeTab === "analytics" ? "active" : ""*/}
              {/*    }`}*/}
              {/*    onClick={() => handleTabClick("analytics")}*/}
              {/*  >*/}
              {/*    Analytics*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              <li className="nav-item">
                <NavLink
                  to="/admin/add"
                  className={`nav-link ${
                    activeTab === "addProduct" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("addProduct")}
                >
                  Add Product
                </NavLink>
                {isMobile?<hr className="hrik"/>:null}
              </li>
              <li className="nav-item">
                <NavLink
                  to="/admin/product/view"
                  className={`nav-link ${
                    activeTab === "viewProduct" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("viewProduct")}
                >
                  View/Update Product
                </NavLink>
                {isMobile?<hr className="hrik"/>:null}
              </li>
              <li className="nav-item">
                <NavLink
                  to="/admin/subcategory/add"
                  className={`nav-link ${
                    activeTab === "addSubcategory" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("addSubcategory")}
                >
                  Add Subcategory
                </NavLink>
                {isMobile?<hr className="hrik"/>:null}
              </li>
              <li className="nav-item">
                <NavLink
                  to="/admin/subcategory/view"
                  className={`nav-link ${activeTab === "view" ? "active" : ""}`}
                  onClick={() => handleTabClick("view")}
                >
                  View/Update Subcategory
                </NavLink>
                {isMobile?<hr className="hrik"/>:null}
              </li>
              <li className="nav-item">
                <NavLink
                  to="/admin/carousel-images"
                  className={`nav-link ${activeTab === "view" ? "active" : ""}`}
                  onClick={() => handleTabClick("view")}
                >
                  Carousel Images
                </NavLink>
                {isMobile?<hr className="hrik"/>:null}
              </li>
              <li className="nav-item">
                <NavLink
                  to="/admin/send-email"
                  className={`nav-link ${activeTab === "view" ? "active" : ""}`}
                  onClick={() => handleTabClick("view")}
                >
                  Send Email
                </NavLink>
              </li>
            </ul>
          </div>
          
        </div>
      </nav>
    </header>
  );
};

export default AdminHeader;
