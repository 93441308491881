import React from "react";

const Policy = () => {


return(
    <>
    <p style={{marginLeft:"20px", marginRight:"30px"}}>
    <h1>Terms and Conditions:</h1>

Welcome to Kanchans Choice. By accessing or using our website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions, as well as our privacy policy. If you do not agree to these terms and conditions, please do not use our website.

<hr/>

<p>
<h4>Use of the Website</h4>
Our website is intended for use by individuals who are at least 18 years old. By using our website, you represent and warrant that you are at least 18 years old.
You may use our website for lawful purposes only.
You are responsible for maintaining the confidentiality of your account and password, and for restricting access to your computer or device.
You are responsible for all activities that occur under your account or password.
You must immediately notify us of any unauthorized use of your account or password or any other breach of security.
</p>

<hr/>

<p>
<h4>Product Information</h4>
We make every effort to ensure that the product information on our website is accurate and up-to-date. However, we cannot guarantee the accuracy or completeness of the information.
Prices and availability of products are subject to change without notice.
We reserve the right to limit the quantity of products we supply, and we may reject any order or cancel any purchase.
</p>

<hr/>

<p>
<h4>Payment</h4>
Payment for products must be made in full at the time of purchase.
We accept payment by credit card or any other payment method we may make available to you.
All payments are processed securely through a third-party payment gateway.
We do not collect your credit/debit card information data. All payment methods are secure and encrypted.

</p>
<hr/>
<p>
<h4>Shipping</h4>
We will charge a flat fee of $7 for shipping through USPS or any other delivery partner.
We currently only ship within the USA.
Shipping times and delivery dates are estimates only, and we are not responsible for any delays or failures to deliver caused by events outside of our control.
</p>

<hr/>
<p>
<h4>Returns and Refunds</h4>
We do not accept any returns and or refunds at all.
</p>

<hr/>
<p>

<h4>Intellectual Property</h4>
All intellectual property rights in our website, including text, graphics, logos, images, and software, are owned by us or our licensors.
You may not use our intellectual property for any commercial purpose without our prior written consent.
</p>

<hr/>
<p>
<h4>Limitation of Liability</h4>
We are not liable for any loss or damage caused by your use of our website or products, including but not limited to any direct, indirect, special, incidental, or consequential damages.
We are not liable for any loss or damage caused by events outside of our control, including but not limited to natural disasters, strikes, and government actions.
</p>
<hr/>
<p>
<h4>Privacy Policy:</h4>

At Kanchans Choice, we are committed to protecting your privacy. We collect personal information from you when you create an account or make a purchase, and we use this information to process your orders, improve our website, and communicate with you about our products and services. We will not share your personal information with any third party, except as necessary to process your orders or comply with applicable laws.

We take reasonable steps to protect your personal information from unauthorized access, disclosure, or misuse. We use industry-standard security measures to protect your information, including encryption, firewalls, and secure servers. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee the absolute security of your information.

By using our website, you consent to the collection and use of your personal information as described in this policy. If we make any material changes to this policy, we will notify you by email or by posting a notice on our website.

</p>
<hr/>
<h4>Credits:</h4>
<p>
New banner for the new products.<br/>
    <a href="https://www.freepik.com/free-vector/new-tag-3d-vector-ribbon-banner-new-collection-badge-digital-marketing-web_30137989.htm#query=new%20corner%20banner&position=0&from_view=search&track=ais">Image by felicities</a> on Freepik</p>

    </p>
    </>
)
}

export default Policy;