import React, { useState } from "react";
import "../../css/Signup.css";
import logo from "../../components/images/logo-transparent-png.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
const SignupPage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [streetAddress1, setStreetAddress1] = useState("");
  const [streetAddress2, setStreetAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [touchedFields, setTouchedFields] = useState({});
  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked);
  };



  const navigate = useNavigate();

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
    setTouchedFields({ ...touchedFields, firstName: true });
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
    setTouchedFields({ ...touchedFields, lastName: true });
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setTouchedFields({ ...touchedFields, email: true });
  };

  const handleStreetAddress1Change = (event) => {
    setStreetAddress1(event.target.value);
    setTouchedFields({ ...touchedFields, streetAddress1: true });
  };

  const handleStreetAddress2Change = (event) => {
    setStreetAddress2(event.target.value);
    setTouchedFields({ ...touchedFields, streetAddress2: true });
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
    setTouchedFields({ ...touchedFields, city: true });
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
    setTouchedFields({ ...touchedFields, state: true });
  };

  const handleZipCodeChange = (event) => {
    setZipCode(event.target.value);
    setTouchedFields({ ...touchedFields, zipCode: true });
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
    setTouchedFields({ ...touchedFields, phoneNumber: true });
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setTouchedFields({ ...touchedFields, password: true });
  };
  const handlePassword2Change = (event) => {
    setPassword2(event.target.value);
    setTouchedFields({ ...touchedFields, password2: true });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      firstName,
      lastName,
      email,
      streetAddress1,
      streetAddress2,
      city,
      state,
      zipCode,
      phoneNumber,
      password,
      password2,
    };

    const errors = validateFormData(formData);
    const apiUrl = `${process.env.REACT_APP_API}`;
    if (Object.keys(errors).length === 0) {
      try {
        const { password2, ...formDataWithoutPassword2 } = formData;
        const response = await fetch(`${apiUrl}/signup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formDataWithoutPassword2),
        });
        const data = await response.json();
        if (response.ok) {
          localStorage.setItem("auth", JSON.stringify(data));
          toast.success("Registration Successful");
          navigate("/login", { replace: true });
        }
        else
        {
          toast.error(data.message);
        }
      } catch (error) {
        console.error(error);
        toast.error("Registration Unsuccessful");
      }
    } else {
      console.error(errors);
    }
  };

  const validateFormData = (formData) => {
    const errors = {};
    if (!formData.firstName) {
      errors.firstName = "First name is required";
    }

    if (!formData.lastName) {
      errors.lastName = "Last name is required";
    }

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email format";
    }

    if (!formData.streetAddress1) {
      errors.streetAddress1 = "Street address is required";
    }

    if (!formData.city) {
      errors.city = "City is required";
    }

    if (!formData.state) {
      errors.state = "State is required";
    }

    if (!formData.zipCode) {
      errors.zipCode = "Zip code is required";
    } else if (!isValidZipCode(formData.zipCode)) {
      errors.zipCode = "Invalid zip code format";
    }

    if (!formData.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    } else if (!isValidPhoneNumber(formData.phoneNumber)) {
      errors.phoneNumber = "Invalid phone number format";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    } else if (!isValidPassword(formData.password)) {
      errors.password =
        "Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 number";
    }

    if (!formData.password2) {
      errors.password2 = "Please confirm your password";
    } else if (formData.password !== formData.password2) {
      errors.password2 = "Passwords do not match";
    }
    return errors;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidZipCode = (zipCode) => {
    const zipCodeRegex = /^\d{5}(-\d{4})?$/;
    return zipCodeRegex.test(zipCode);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^\d{10}$/;
    return phoneNumberRegex.test(phoneNumber);
  };

  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    return passwordRegex.test(password);
  };
  const errors = validateFormData({
    firstName,
    lastName,
    email,
    streetAddress1,
    city,
    state,
    zipCode,
    phoneNumber,
    password,
    password2,
  });
  return (
    <>
      <Header></Header>
      <div className="container mt-4 ">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-6 col-sm-8 p-5">
            <div className="card signupForm shadow-lg bb">
              <div className="card-body ">
                <div className="text-center">
                <img
                    src={logo}
                    alt="Logo"
                    width="150"
                    height="60"
                    className="ml-5 mt-3 mb-5"
                  />
                </div>
                <form onSubmit={handleSubmit} className="mb-6">
                  <div class="justify-content-between">
                    <div className="form-group col-sm-6 form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        placeholder="First name"
                        value={firstName}
                        onChange={handleFirstNameChange}
                      />
                      <div
                        style={{
                          height:
                            touchedFields.firstName && errors.firstName
                              ? "2px"
                              : "0",
                        }}
                      ></div>
                      <label htmlFor="floatingInput">First Name</label>
                      {touchedFields.firstName && errors.firstName && (
                        <div className="error">{errors.firstName}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-6 form-floating">
                      <input
                        type="text"
                        className="form-control lastname"
                        id="lastName"
                        placeholder="Last name"
                        value={lastName}
                        onChange={handleLastNameChange}
                      />
                      <div
                        style={{
                          height:
                            touchedFields.lastName && errors.lastName
                              ? "2px"
                              : "0",
                        }}
                      ></div>
                      <label htmlFor="floatingInput">Last Name</label>
                      {touchedFields.lastName && errors.lastName && (
                        <div className="error">{errors.lastName}</div>
                      )}
                    </div>
                  </div>

                  <div className=" justify-content-between">
                    <div className="form-group col-sm-6 form-floating">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                      />
                      <div
                        style={{
                          height:
                            touchedFields.email && errors.email ? "2px" : "0",
                        }}
                      ></div>
                      <label htmlFor="floatingInput">Email address</label>
                      {touchedFields.email && errors.email && (
                        <div className="error">{errors.email}</div>
                      )}
                    </div>
                  </div>
                
                  {/* Address */}
                  <div className=" justify-content-between">
                    <div className="form-group col-sm-6 form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="streetAddress1"
                        placeholder="Street Address"
                        value={streetAddress1}
                        onChange={handleStreetAddress1Change}
                      />
                      <div
                        style={{
                          height:
                            touchedFields.streetAddress1 &&
                            errors.streetAddress1
                              ? "2px"
                              : "0",
                        }}
                      ></div>
                      <label htmlFor="floatingInput">Street Address</label>
                      {touchedFields.streetAddress1 &&
                        errors.streetAddress1 && (
                          <div className="error">{errors.streetAddress1}</div>
                        )}
                    </div>
                  </div>

                  <div className=" justify-content-between">
                    <div className="form-group col-sm-6 form-floating">
                      <input
                        type="text"
                        className="form-control add"
                        id="streetAddress2"
                        placeholder="House, Apt Number"
                        value={streetAddress2}
                        onChange={handleStreetAddress2Change}
                      />
                      <div
                        style={{
                          height:
                            touchedFields.streetAddress2 &&
                            errors.streetAddress2
                              ? "2px"
                              : "0",
                        }}
                      ></div>
                      <label htmlFor="floatingInput">House, Apt Number</label>
                      {touchedFields.streetAddress2 &&
                        errors.streetAddress2 && (
                          <div className="error">{errors.streetAddress2}</div>
                        )}
                    </div>
                  </div>

                  <div className=" d-flex justify-content-between">
                    <div className="form-group col-sm-6 form-floating">
                      <input
                        type="text"
                        className="form-control city"
                        id="city"
                        placeholder="City"
                        value={city}
                        onChange={handleCityChange}
                      />
                      <div
                        style={{
                          height:
                            touchedFields.city && errors.city ? "2px" : "0",
                        }}
                      ></div>
                      <label htmlFor="floatingInput"> City </label>
                      {touchedFields.city && errors.city && (
                        <div className="cityerror">{errors.city}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-5 form-floating">
                      <select
                        className="form-control state"
                        id="state"
                        value={state}
                        onChange={handleStateChange}
                      >
                        <option value="" className="state">
                          State
                        </option>
                        {states.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                      <div
                        style={{
                          height:
                            touchedFields.state && errors.state ? "2px" : "0",
                        }}
                      ></div>
                      <label htmlFor="floatingInput"> State </label>
                      {touchedFields.state && errors.state && (
                        <div className="error">{errors.state}</div>
                      )}
                    </div>
                  </div>
                  <div className=" justify-content-between">
                    <div className="form-group col-sm-6 form-floating">
                      <input
                        type="text"
                        className="form-control zip"
                        id="zipcode"
                        placeholder="Zipcode"
                        value={zipCode}
                        onChange={handleZipCodeChange}
                      />
                      <div
                        style={{
                          height:
                            touchedFields.zipCode && errors.zipCode
                              ? "2px"
                              : "0",
                        }}
                      ></div>
                      <label htmlFor="floatingInput"> Zip Code </label>
                      {touchedFields.zipCode && errors.zipCode && (
                        <div className="error">{errors.zipCode}</div>
                      )}
                    </div>
                  </div>

                  {/* PhoneNumber */}
                  <div className=" justify-content-between">
                    <div className="form-group col-sm-6 form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                      />
                      <div
                        style={{
                          height:
                            touchedFields.phoneNumber && errors.phoneNumber
                              ? "2px"
                              : "0",
                        }}
                      ></div>
                      <label htmlFor="floatingInput"> Phone Number </label>
                      {touchedFields.phoneNumber && errors.phoneNumber && (
                        <div className="error">{errors.phoneNumber}</div>
                      )}
                    </div>
                  </div>

                  {/* Password */}
                  <div className=" justify-content-between">
                    <div className="form-group col-sm-6 form-floating">
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                      <div
                        style={{
                          height:
                            touchedFields.password && errors.password
                              ? "2px"
                              : "0",
                        }}
                      ></div>
                      <label htmlFor="floatingInput"> Password </label>
                      
                    </div>
                  </div>
                  <div className=" justify-content-between">
                    <div className="form-group col-sm-6 form-floating">
                      <input
                        type="password"
                        className="form-control pass"
                        id="password2"
                        placeholder="Confirm password"
                        value={password2}
                        onChange={handlePassword2Change}
                      />
                      <div
                        style={{
                          height:
                            touchedFields.password2 && errors.password2
                              ? "2px"
                              : "0",
                        }}
                      ></div>
                      <label htmlFor="floatingInput"> Confirm Password</label>
          
                    </div>
                  </div>
                  <div className="justify-content-between">
                    <div className="form-group form-floating">
                      {touchedFields.password2 && errors.password2 && (
                        <div className="error">{errors.password2}</div>
                      )}
                      {touchedFields.password && errors.password && (
                        <div className="error">{errors.password}</div>
                      )}
                    </div>
                      
                  </div>
                  <br/>

                  {/* Terms and COnditions */}
                  <div className="justify-content-between">
                    <div style={{marginBottom:"50px", marginTop:"20px"}}>
                    <label>
                          <input type="checkbox" checked={termsAccepted} onChange={handleTermsChange} style={{marginRight:"4px"}} />
                          &nbsp; I accept the &nbsp;<a href="/terms&conditions" className="text-decoration-none text-reset " target="_blank"><b className="termscondition-link">Terms and Conditions</b></a>
                    </label>
                    </div> 
                  </div>

                  
                  
                  <div className="text-center" style={{ marginTop:"10px"}}>
                          <button
                            type="submit"
                            className="btn button mb-3 sub col-7 signupbtn"
                            disabled={!termsAccepted}
                            style={termsAccepted ? {} : { backgroundColor: 'grey', color: 'white' }}

                          >
                            Sign Up
                          </button>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default SignupPage;

